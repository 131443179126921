import { Button, Grid, TextField, CircularProgress, Select, MenuItem } from '@mui/material'
import React, { useState, useContext } from 'react'
import { makeStyles } from '@mui/styles'
import { AuthContext } from 'contexts/authContext'
import { UserInfo, UserRole } from 'type/userInfo'
import { EventsInfo } from 'type/eventsInfo'
import { getEventsById, updateEventsById } from 'libs/route'

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '4vh' },
  title: {
    textAlign: 'center',
  },
  container: {
    marginTop: 20,
    alignItems: 'top',
    width: '100%',
  },
  border: {
    border: '1px solid black', // add a border to the bottom of each row
  },
  headerCell: {
    backgroundColor: '#f1f1f1',
    color: 'black',
    borderCollapse: 'collapse',
    maxWidth: '300px',
    minWidth: '240px',
    paddingTop: '12px',
    paddingBottom: '12px',
    marginBottom: '8px', // Add margin-bottom for spacing between rows
  },
  narrowHeadCell: {
    backgroundColor: '#f1f1f1',
    color: 'black',
    borderCollapse: 'collapse',
    maxWidth: '120px',
    minWidth: '100px',
    paddingTop: '12px',
    paddingBottom: '12px',
    marginBottom: '16px', // Add margin-bottom for spacing between rows
  },
  narrowCell: {
    maxWidth: '120px',
    minWidth: '100px',
    paddingTop: '12px',
    paddingBottom: '12px',
    marginBottom: '16px', // Add margin-bottom for spacing between rows
  },
  cell: {
    maxWidth: '300px',
    minWidth: '240px',
    paddingTop: '12px',
    paddingBottom: '12px',
    marginBottom: '16px', // Add margin-bottom for spacing between rows
    textAlign: 'center',
  },
  textCell: {
    width: '95%',
    '& .MuiInputBase-input': {
      fontSize: '12px', // Set the size you want here
    },
  },
  styledDateInput: {
    padding: '8px',
    fontSize: '16px',
    border: 'none',
    cursor: 'pointer',
    borderBottom: '1px solid gray', // Add a bottom border
    outline: 'none', // Remove the default outline
  },
  button: {
    backgroundColor: '#ffd40a',
    marginBottom: 20,
    color: 'black',
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
}))

function EventsPage({ userInfo, events, setEvents }: { userInfo: UserInfo; events: EventsInfo[]; setEvents: any }) {
  const classes = useStyles()
  const [activites, setActivities] = useState(
    events && events.length > 0
      ? events
      : [
          {
            event_type: '',
            event_name: '',
            description: '',
            start_date: '',
            end_date: '',
            total_length: '',
            organizer: '',
          },
        ]
  )
  const auth = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)

  const addRow = () => {
    setActivities((prevEvents) => [
      ...prevEvents,
      {
        event_type: '',
        event_name: '',
        description: '',
        start_date: '',
        end_date: '',
        total_length: '',
        organizer: '',
      },
    ])
  }

  const onSaveClicked = async () => {
    setIsLoading(true)
    if (JSON.stringify(events) === JSON.stringify(activites)) {
      alert('没有修改')
      setIsLoading(false)
      return
    }

    for (const activity of activites) {
      if (
        activity.event_type === '' ||
        activity.event_name === '' ||
        activity.description === '' ||
        activity.start_date === '' ||
        activity.end_date === '' ||
        activity.total_length === '' ||
        activity.organizer === ''
      ) {
        alert('请填写完整信息')
        setIsLoading(false)
        return
      }
    }

    const response = await updateEventsById(userInfo.username, auth, activites)
    if (response.status === 200) {
      alert('修改成功')
      const eventsInfo = await getEventsById(userInfo.username, auth.sessionInfo.idToken!)
      setEvents(eventsInfo)
    } else {
      alert('修改失败')
    }
    setIsLoading(false)
  }

  const deleteRow = (index) => {
    setActivities((prevEvents) => prevEvents.filter((_, i) => i !== index))
  }

  const handleInputChange = (index, field, value) => {
    setActivities((prevEvents) => {
      const updatedEvents = [...prevEvents]
      updatedEvents[index] = {
        ...updatedEvents[index],
        [field]: value,
      }
      return updatedEvents
    })
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <Grid>
      <div style={{ overflowX: 'auto', marginLeft: 40, marginRight: 40 }}>
        <table className={classes.container}>
          <thead>
            <tr>
              <th className={classes.narrowHeadCell}>类型</th>
              <th className={classes.narrowHeadCell}>活动名称</th>
              <th className={classes.headerCell}>具体描述</th>
              <th className={classes.headerCell}>开始时间</th>
              <th className={classes.headerCell}>结束时间</th>
              <th className={classes.narrowHeadCell}>总时长(小时)</th>
              <th className={classes.narrowHeadCell}>组织方</th>
            </tr>
          </thead>
          <tbody className={classes.container}>
            {activites.map((activity, index) => (
              <tr key={index}>
                <td className={classes.narrowCell}>
                  <Select
                    className={classes.textCell}
                    disabled={
                      // Disable for parent role
                      auth.userInfo?.role === UserRole.Parent
                    }
                    value={activity.event_type}
                    onChange={(e) => handleInputChange(index, 'event_type', e.target.value)}
                  >
                    <MenuItem value={'社团活动 Club'}>社团活动 Club</MenuItem>
                    <MenuItem value={'社会服务 Service'}>社会服务 Service</MenuItem>
                    <MenuItem value={'志愿者 Volunteer'}>志愿者 Volunteer</MenuItem>
                    <MenuItem value={'实习经验 Internship/Work'}>实习经验 Internship/Work</MenuItem>
                    <MenuItem value={'体育项目 Athletics'}>体育项目 Athletics</MenuItem>
                    <MenuItem value={'艺术项目 Art'}>艺术项目 Art</MenuItem>
                  </Select>
                </td>
                <td className={classes.cell}>
                  <TextField
                    multiline
                    className={classes.textCell}
                    disabled={
                      // Disable for parent role
                      auth.userInfo?.role === UserRole.Parent
                    }
                    value={activity.event_name}
                    onChange={(e) => handleInputChange(index, 'event_name', e.target.value)}
                  />
                </td>
                <td className={classes.cell}>
                  <TextField
                    multiline
                    className={classes.textCell}
                    disabled={
                      // Disable for parent role
                      auth.userInfo?.role === UserRole.Parent
                    }
                    value={activity.description}
                    onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                  />
                </td>
                <td className={classes.cell}>
                  <input
                    type="date"
                    id="datepicker"
                    disabled={
                      // Disable for parent role
                      auth.userInfo?.role === UserRole.Parent
                    }
                    className={classes.styledDateInput}
                    onChange={(e) => handleInputChange(index, 'start_date', e.target.value)}
                    value={activity.start_date}
                  />
                </td>
                <td className={classes.cell}>
                  <input
                    type="date"
                    id="datepicker"
                    disabled={
                      // Disable for parent role
                      auth.userInfo?.role === UserRole.Parent
                    }
                    className={classes.styledDateInput}
                    onChange={(e) => handleInputChange(index, 'end_date', e.target.value)}
                    value={activity.end_date}
                  />
                </td>
                <td className={classes.narrowCell}>
                  <TextField
                    className={classes.textCell}
                    multiline
                    value={activity.total_length}
                    disabled={
                      // Disable for parent role
                      auth.userInfo?.role === UserRole.Parent
                    }
                    onChange={(e) => handleInputChange(index, 'total_length', e.target.value)}
                  />
                </td>
                <td className={classes.narrowCell}>
                  <TextField
                    className={classes.textCell}
                    multiline
                    value={activity.organizer}
                    disabled={
                      // Disable for parent role
                      auth.userInfo?.role === UserRole.Parent
                    }
                    onChange={(e) => handleInputChange(index, 'organizer', e.target.value)}
                  />
                </td>
                {
                  // Disable for parent role
                  auth.userInfo?.role === UserRole.Parent ? null : (
                    <td>
                      <Button
                        className={classes.button}
                        onClick={() => deleteRow(index)}
                        variant="contained"
                        style={{ marginLeft: 20, float: 'left', width: 80 }}
                      >
                        删除
                      </Button>
                    </td>
                  )
                }
              </tr>
            ))}
          </tbody>
        </table>
        {auth.userInfo!['role'] === UserRole.Parent ? (
          <div />
        ) : (
          <div>
            <Button
              className={classes.button}
              onClick={addRow}
              variant="contained"
              style={{ marginTop: 40, float: 'left' }}
            >
              新增一行
            </Button>
            <Button
              className={classes.button}
              onClick={onSaveClicked}
              variant="contained"
              style={{ marginTop: 40, float: 'left', marginLeft: 40 }}
            >
              保存修改
            </Button>
          </div>
        )}
      </div>
    </Grid>
  )
}

export default EventsPage
