import { useState } from 'react'

const useErrorHandling = () => {
  const [error, setError] = useState<string>()
  const handleError = (errorMessage: string) => {
    setError(errorMessage)
  }

  const clearError = () => {
    setError(null)
  }

  return { error, handleError, clearError }
}

export default useErrorHandling
