import Grid from '@mui/material/Grid'

import Box from '@mui/material/Box'
import { Button, CircularProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useContext, useEffect, useState } from 'react'
import ProfileBasicInfoButton from './ProfileBasicInfoButton'
import { AuthContext } from 'contexts/authContext'
import { updateUserById } from 'libs/route'
import { UserInfo, ProfileFieldData, studentProfileFields, parentProfileFields, UserRole } from 'type/userInfo'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 40,
    marginRight: 40,
    marginTop: 20,
  },
  title: {
    textAlign: 'center',
  },
  content: {
    marginTop: 20,
  },
  button: {
    backgroundColor: '#ffd40a',
    marginInline: 'auto',
    marginTop: 16,
    marginBottom: 25,
    color: 'black',
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
})

export default function ProfileSheet({ studentInfo, parentsInfo }: { studentInfo: UserInfo; parentsInfo: UserInfo[] }) {
  const [currentStudentInfo, setCurrentStudentInfo] = useState<UserInfo>(studentInfo)
  const [currentParentsInfo, setCurrentParentsInfo] = useState<UserInfo[]>(parentsInfo)
  const [isLoading, setIsLoading] = useState(false)
  const auth = useContext(AuthContext)
  const classes = useStyles()

  useEffect(() => {
    setCurrentStudentInfo(studentInfo)
  }, [studentInfo])

  useEffect(() => {
    setCurrentParentsInfo(parentsInfo)
  }, [parentsInfo])

  const onSaveClick = async (oldUserInfo: UserInfo, currUserInfo: UserInfo | UserInfo[]) => {
    setIsLoading(true)
    if (Array.isArray(currUserInfo)) {
      currUserInfo = currUserInfo.find((user) => user.username === oldUserInfo.username)
    }
    if (JSON.stringify(oldUserInfo) === JSON.stringify(currUserInfo)) {
      alert('没有修改')
      setIsLoading(false)
      return
    }
    const response = await updateUserById(oldUserInfo.username, auth, currUserInfo)
    setIsLoading(false)
    if (response.status === 200) {
      alert('修改成功')
    } else {
      alert('修改失败')
    }
  }

  const role = auth.userInfo!['role'] as UserRole
  const checkUpdatePermission = (fieldData: ProfileFieldData): boolean => {
    // Determines if a user has permission to write on a personal info column
    return fieldData.canUpdateRoles.includes(role)
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <Grid container direction="row" className={classes.content}>
        {/* Student User Info */}
        <Grid item xs={12} md={6} direction="column">
          <Box>
            <Typography variant="h6">学生信息</Typography>
            {Object.keys(studentProfileFields).map((field) => {
              const fieldData = studentProfileFields[field]
              return (
                <Grid item key={field} className={classes.content}>
                  <ProfileBasicInfoButton
                    key={field}
                    label={fieldData.clabel}
                    isDisabled={!checkUpdatePermission(studentProfileFields[field])}
                    content={currentStudentInfo[field]}
                    attr={field}
                    setStudentUserInfo={setCurrentStudentInfo}
                  />
                </Grid>
              )
            })}
            {role !== UserRole.Parent && (
              <Button
                className={classes.button}
                onClick={() => {
                  onSaveClick(studentInfo, currentStudentInfo)
                }}
                variant="contained"
                style={{ marginLeft: '10px' }}
              >
                保存修改
              </Button>
            )}
          </Box>
        </Grid>
        {parentsInfo.length > 0 && (
          // Parents' Info
          <Grid container item xs={12} md={6} direction="column">
            <Box>
              <Typography variant="h6">家长信息</Typography>
              {parentsInfo.map((parent) => (
                <div key={parent.username}>
                  {Object.keys(parentProfileFields).map((field) => {
                    const fieldData = parentProfileFields[field]
                    const currentParentInfo = Array.isArray(currentParentsInfo)
                      ? currentParentsInfo.find((parentInfo) => parentInfo.username === parent.username)
                      : (currentParentsInfo as UserInfo)
                    return (
                      <Grid item key={field} className={classes.content}>
                        <ProfileBasicInfoButton
                          key={field}
                          label={fieldData.clabel}
                          isDisabled={!checkUpdatePermission(parentProfileFields[field])}
                          content={currentParentInfo[field]}
                          attr={field}
                          setParentUsersInfo={setCurrentParentsInfo}
                          parentId={parent.username}
                        />
                      </Grid>
                    )
                  })}
                  {role !== UserRole.Student && (
                    <Button
                      className={classes.button}
                      onClick={() => {
                        onSaveClick(parent, currentParentsInfo)
                      }}
                      variant="contained"
                      style={{ marginLeft: '10px' }}
                    >
                      保存修改
                    </Button>
                  )}
                </div>
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
