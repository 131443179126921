import { useState, useEffect, useContext } from 'react'

import closeImage from '../Materials/close.png'
import { AuthContext } from 'contexts/authContext'
import { sendRequestById } from 'libs/route'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Box, TextField, Typography, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  footer: {
    padding: '2vh',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  formContainer: {
    background: 'white',
    padding: 50,
    borderRadius: 10,
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    width: '80%',
    maxWidth: 1000,
  },
  button: {
    backgroundColor: '#ffd40a',
    color: 'black',
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
})

const Footer: React.FunctionComponent = () => {
  const classes = useStyles()

  const [showForm, setShowForm] = useState(false)
  const [title, setTitle] = useState('')
  const [subject, setSubject] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const auth = useContext(AuthContext)

  const [user, setUser] = useState(auth.userInfo!)

  useEffect(() => {
    const userInfo = auth.userInfo!
    setUser(userInfo)
  }, [auth.userInfo])

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const response = await sendRequestById(user.username, auth.sessionInfo?.idToken!, title, subject)
      setIsLoading(false)
      if (response.status === 200) {
        alert('成功提交反馈')
        setShowForm(false)
        setTitle('')
        setSubject('')
      } else {
        alert(`反馈提交失败，请重试`)
      }
    } catch (error) {
      console.error('Error sending inquiry:', error)
      alert('反馈提交失败，请重试')
    }
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <Grid className={classes.footer}>
      <Box alignItems="center" style={{ textAlign: 'center' }}>
        <Typography
          className={classes.hover}
          style={{ display: 'inline-block' }}
          align="center"
          onClick={() => setShowForm(true)}
        >
          遇到问题？联系我们
        </Typography>
      </Box>
      {showForm && (
        <div className={classes.overlay}>
          <div className={classes.formContainer}>
            <img
              className={classes.hover}
              src={closeImage}
              style={{ float: 'right', marginTop: -20, marginRight: -10 }}
              width={'25px'}
              alt="cover"
              onClick={() => {
                setShowForm(false)
                setTitle('')
                setSubject('')
              }}
            />
            <h2>联系我们</h2>
            <form onSubmit={handleSubmit}>
              <label>
                <Typography align="left">标题</Typography>
                <TextField
                  style={{ width: '20vw' }}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </label>
              <br />
              <br />
              <label>
                <Typography align="left">内容</Typography>
                <TextField
                  multiline
                  style={{ width: '20vw' }}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
              </label>
              <br />
              <br />
              <Button
                className={classes.button}
                style={{ float: 'left' }}
                variant="contained"
                type="submit"
                disabled={isLoading}
              >
                提交
              </Button>
            </form>
          </div>
        </div>
      )}
    </Grid>
  )
}
export default Footer
