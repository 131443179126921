import Grid from '@mui/material/Grid'

import { Button, CircularProgress, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'contexts/authContext'
import { HomestayInfo, homestayProfileFields, homestayProfileFieldData } from 'type/homestayInfo'
import { getHomestays, getHomestayByEmail, updateHomestayByEmail } from 'libs/route'
import ProfileBasicInfoButton from '../Profile/ProfileBasicInfoButton'

const useStyles = makeStyles({
  button: {
    flexGrow: 1,
    padding: '10px',
    border: 'none',
    backgroundColor: '#f1f1f1',
    cursor: 'pointer',
    color: '#333',
    fontWeight: 'bold',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  root: {
    height: '100%',
  },
  title: {
    textAlign: 'center',
  },
  content: {
    backgroundColor: '#FFFFFF',
    width: '95%',
    marginTop: 20,
    borderRadius: 10,
  },
  headerButton: {
    backgroundColor: '#ffd40a',
    color: 'black',
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
  menu: {
    backgroundColor: '#ffd40a',
    color: 'black',
    boxShadow: '4px 0px 5px 2px rgba(0, 0, 0, .1)',
    padding: '1vh',
    height: '7vh',
  },
})

const HomestayPage = () => {
  const classes = useStyles()
  const { id } = useParams<{ id: string }>()
  const auth = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [currentHomestayInfo, setCurrentHomestayInfo] = useState<HomestayInfo>({})
  const [oldHomestayInfo, setOldHomestayInfo] = useState<HomestayInfo>({})
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.setItem('history', `${location.pathname}`)
  }, [location])

  // Handle fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)

        const homestayArray = await getHomestays(auth.sessionInfo?.idToken)
        console.log('homestayArray', homestayArray)

        const homestayIndex = id ? parseInt(id) : 0
        const homestay = homestayArray[homestayIndex]
        if (homestay) {
          try {
            const homestayData = await getHomestayByEmail(homestay.email, auth.sessionInfo?.idToken)
            setOldHomestayInfo(homestayData)
            setCurrentHomestayInfo(homestayData)
          } catch (error) {
            console.error('Error fetching homestay data by email:', error)
          }
        } else {
          // Handle the case where the homestay is not found.
          console.error('Homestay not found for index:', homestayIndex)
        }

        setIsLoading(false)
      } catch (error) {
        console.error('Error fetching homestay data:', error)
      }
    }

    fetchData()
  }, [id, auth.sessionInfo?.idToken])

  // Handle save click
  const onSaveClick = async (oldHomestayInfo: HomestayInfo, currHomestayInfo: HomestayInfo | HomestayInfo[]) => {
    setIsLoading(true)
    if (Array.isArray(currHomestayInfo)) {
      currHomestayInfo = currHomestayInfo.find((homestay) => homestay.email === oldHomestayInfo.email)
    }
    if (JSON.stringify(oldHomestayInfo) === JSON.stringify(currHomestayInfo)) {
      alert('没有修改')
      setIsLoading(false)
      return
    }
    const response = await updateHomestayByEmail(oldHomestayInfo.email, auth, currHomestayInfo)
    setIsLoading(false)
    if (response.status === 200) {
      alert('修改成功')
    } else {
      alert('修改失败')
    }
  }

  // Check field permission
  const checkUpdatePermission = (fieldData: homestayProfileFieldData): boolean => {
    // Determines if this field can be updated
    return fieldData.canUpdate
  }

  const renderBackButton = () => {
    if (location.pathname === '/profiles') {
      return <div />
    }
    return (
      <Button
        className={classes.headerButton}
        onClick={() => {
          localStorage.removeItem('history')
          navigate('/profiles', { state: { selectedTab: 'homestay' }, replace: true })
        }}
        id={'back'}
        variant="contained"
      >
        返回
      </Button>
    )
  }

  if (isLoading) {
    return (
      <>
        <Grid container alignItems="center">
          <Grid container className={classes.menu} direction="column" justifyContent="center" alignItems="flex-end" />
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '30',
          }}
        >
          <CircularProgress />
        </div>
      </>
    )
  }

  return (
    <>
      <Grid container alignItems="center">
        <Grid container className={classes.menu} direction="column" justifyContent="center" alignItems="flex-end">
          <Box display="flex" flexDirection="row" alignItems="center" style={{ position: 'relative', right: '1vw' }}>
            {renderBackButton()}
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.content} style={{ marginInline: 'auto' }}>
        {/* Homestay Info */}
        <Grid item xs={12} md={6} direction="column">
          <div
            style={{
              paddingLeft: '20px',
            }}
          >
            <h3>住家信息：</h3>
          </div>
          <div
            style={{
              border: '1px solid #ccc',
              padding: '16px',
              borderRadius: '4px',
              boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)',
              margin: '20px',
            }}
          >
            {Object.keys(homestayProfileFields).map((field) => {
              const fieldData = homestayProfileFields[field]
              return (
                <Grid item key={field}>
                  <ProfileBasicInfoButton
                    key={field}
                    label={fieldData.clabel}
                    isDisabled={!checkUpdatePermission(homestayProfileFields[field])}
                    content={currentHomestayInfo[field]}
                    attr={field}
                    setHomestayInfo={setCurrentHomestayInfo}
                  />
                </Grid>
              )
            })}
            <Button
              className={classes.button}
              onClick={() => {
                onSaveClick(oldHomestayInfo, currentHomestayInfo)
              }}
              variant="contained"
              style={{ marginLeft: '30px' }}
            >
              保存修改
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default HomestayPage
