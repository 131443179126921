import '../../App.css'
import CssBaseline from '@mui/material/CssBaseline'

import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, HashRouter as Router, Route, Routes } from 'react-router-dom'

import { makeStyles } from '@mui/styles'
import AuthProvider, { AuthContext, AuthIsSignedIn, AuthIsNotSignedIn } from '../../contexts/authContext'
import Home from '../Home/home'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import coverImage from '../Materials/cover.png'
import titleImage from '../Materials/title.png'
import SignIn from '../Auth/signIn'
import { Typography } from '@mui/material'
import RequestCode from 'components/Auth/requestCode'
import ForgotPassword from 'components/Auth/forgotPassword'
import ChangePassword from 'components/Auth/changePassword'

const useStyles = makeStyles({
  leftBody: {
    background: 'linear-gradient(to bottom, #ffe65c, #ffd40a)',
    boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, .1)',
  },
  rightBody: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '4px 3px 5px 2px rgba(0, 0, 0, .1)',
  },
  gridItem: {
    marginTop: '20vh',
    marginBottom: '20vh',
  },
  button: {
    backgroundColor: '#ffd40a',
    color: 'black',
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
  customColor: {
    color: '#8e8e8e',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
})

const SignInPage: React.FunctionComponent = () => {
  const classes = useStyles()

  return (
    <Grid>
      <Grid container className={classes.gridItem}>
        <Grid item sm={2} /> {/* Left "margin" */}
        <Grid container className={classes.leftBody} direction="column" alignItems="center" xs={12} sm={4}>
          <Box>
            <img src={titleImage} width={'40%'} style={{ marginLeft: 10, marginTop: 10 }} alt="title" />
            <Box height={'5vh'} />
            <img src={coverImage} width={'100%'} style={{ marginBottom: '-1vh' }} alt="cover" />
          </Box>
        </Grid>
        <Grid
          container
          className={classes.rightBody}
          direction="column"
          alignItems="center"
          justifyContent="center"
          xs={12}
          sm={4}
        >
          <SignIn />
        </Grid>
        <Grid item sm={2} /> {/* Right "margin" */}
      </Grid>
    </Grid>
  )
}

const WelcomePage: React.FunctionComponent = () => {
  const classes = useStyles()

  const auth = useContext(AuthContext)
  const email = localStorage.getItem('email')
  const name = localStorage.getItem('name')

  const [user, setUser] = useState({})

  const navigate = useNavigate()

  const signIn = () => {
    navigate('/home', { replace: true })
  }

  useEffect(() => {
    const userInfo = auth.userInfo!
    setUser(userInfo)
  }, [auth.userInfo])

  const signOutClicked = () => {
    // Clear entire session storage
    sessionStorage.clear()
    console.log('After clearing session storage:', JSON.parse(sessionStorage.getItem(`user_${auth.userId}`)))

    auth.signOut()
    navigate('/', { replace: true })
    localStorage.removeItem('navigation')
    localStorage.removeItem('history')
    localStorage.removeItem('email')
    localStorage.removeItem('name')
  }

  const welcomePage = (
    <Grid>
      <Grid container className={classes.gridItem}>
        <Grid item sm={2} /> {/* Left "margin" */}
        <Grid container className={classes.leftBody} direction="column" alignItems="center" xs={12} sm={4}>
          <Box>
            <img src={titleImage} width={'40%'} style={{ marginLeft: 10, marginTop: 10 }} alt="title" />
            <Box height={'5vh'} />
            <img src={coverImage} width={'100%'} style={{ marginBottom: '-1vh' }} alt="cover" />
          </Box>
        </Grid>
        <Grid
          container
          className={classes.rightBody}
          direction="column"
          alignItems="center"
          justifyContent="center"
          xs={12}
          sm={4}
        >
          <Box>
            <Typography variant="h6">当前登入账号</Typography>
            <Typography className={classes.customColor} id={'email'}>
              {email}
            </Typography>
            <Typography className={classes.customColor} id={'name'}>
              {name ? name : auth.userInfo.name}
            </Typography>
          </Box>
          <Box height={'5vh'} />
          <Box>
            <Button
              onClick={signIn}
              disabled={Object.keys(user).length === 0}
              className={classes.button}
              id="login"
              variant="contained"
            >
              进入主页
            </Button>
          </Box>
          <Box onClick={signOutClicked} mt={2}>
            <Typography className={classes.hover} variant="body2" id="logout">
              退出登录？
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={2} /> {/* Right "margin" */}
      </Grid>
    </Grid>
  )

  return (
    <Routes>
      <Route path="/" key="/" element={welcomePage} />
      <Route path="/home" key="home" element={<Home index={-1} />} />
    </Routes>
  )
}

const MainRoute: React.FunctionComponent = () => (
  <Router>
    <Routes>
      <Route path="/changepassword" element={<ChangePassword />} />
      <Route path="/home" element={<Home index={-1} />} />
      <Route path="/profiles" element={<Home index={0} />} />
      <Route path="/profile/:id" element={<Home index={1} />} />
      <Route path="/profile" element={<Home index={2} />} />
      <Route path="/homestay/:id" element={<Home index={3} />} />
      <Route path="/" element={<WelcomePage />} />
    </Routes>
  </Router>
)

const SignInRoute: React.FunctionComponent = () => (
  <Router>
    <Routes>
      <Route path="/requestcode" element={<RequestCode />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/" element={<SignInPage />} />
    </Routes>
  </Router>
)

const Landing: React.FunctionComponent = () => {
  useEffect(() => {
    document.body.style.backgroundColor = '#fffce0'
  }, [])

  return (
    <Grid>
      <CssBaseline />
      <AuthProvider>
        <AuthIsSignedIn>
          <MainRoute />
        </AuthIsSignedIn>
        <AuthIsNotSignedIn>
          <SignInRoute />
        </AuthIsNotSignedIn>
      </AuthProvider>
    </Grid>
  )
}

export default Landing
