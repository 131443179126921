import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Button } from '@mui/material'
import EditProfileModal from 'components/utils/editProfileModal'
import { UserInfo } from 'type/userInfo'
import { HomestayInfo } from 'type/homestayInfo'

export default function ProfileBasicInfoButton({
  isDisabled = false,
  label,
  attr,
  content,
  setStudentUserInfo,
  setParentUsersInfo,
  setHomestayInfo,
  parentId,
}: {
  isDisabled?: boolean
  label: string
  attr: string
  content?: string
  setStudentUserInfo?: Dispatch<SetStateAction<UserInfo>>
  setParentUsersInfo?: Dispatch<SetStateAction<UserInfo[]>>
  setHomestayInfo?: Dispatch<SetStateAction<HomestayInfo>>
  parentId?: string
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState('')

  useEffect(() => {
    setData(content)
  }, [content])

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const updateContent = (newData) => {
    if (setStudentUserInfo) {
      setStudentUserInfo((prev) => ({ ...prev, [attr]: newData }))
    }
    if (setParentUsersInfo) {
      setParentUsersInfo((prevUsers) =>
        prevUsers.map((user) => {
          if (user.username === parentId) {
            return {
              ...user,
              [attr]: newData,
            }
          }
          return user
        })
      )
    }
    if (setHomestayInfo) {
      setHomestayInfo((prev) => ({ ...prev, [attr]: newData }))
    }
    setData(newData)
  }

  return (
    <div>
      <EditProfileModal
        content={content}
        updateContent={updateContent}
        isOpen={isOpen}
        closeModal={closeModal}
        label={label}
        attr={attr}
      />
      <Button
        onClick={openModal}
        disabled={isDisabled}
        id={parentId ? `parent-${attr}` : attr}
        style={{
          float: 'left',
          color: isDisabled ? '#8e8e8e' : 'black',
          textTransform: 'none',
          width: '100%',
          justifyContent: 'left',
        }}
      >
        {label}: {data}
      </Button>
    </div>
  )
}
