import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Landing from 'components/Home/landing'

ReactDOM.render(
  <React.StrictMode>
    <Landing />
  </React.StrictMode>,
  document.getElementById('root')
)
