import { FeedbackInfo } from 'type/feedbackinfo'
import { GradeInfo } from '../type/gradeInfo'
import { UserInfo } from '../type/userInfo'
import { ActionInfo } from 'type/actionInfo'
import { HomestayInfo } from '../type/homestayInfo'
import { IAuth } from 'contexts/authContext'
import { EventsInfo } from 'type/eventsInfo'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || Cypress.env('REACT_APP_API_ENDPOINT')

/**
 * GET /v1/users
 * @param authToken
 * @param studentId (optional)
 * @param role (optional)
 * @returns List of users (returns all users if no query params are passed)
 */
export const getUsers = async (authToken: string, studentId?: string, role?: string) => {
  // Construct the optional query parameters
  let queryParams = ''

  if (studentId) {
    queryParams += `?studentId=${encodeURIComponent(studentId)}`
  }

  if (role) {
    // Check if other query parameters have been added
    queryParams += studentId ? '&' : '?'
    queryParams += `role=${encodeURIComponent(role)}`
  }
  const cacheKey = `users_studentEmail=${studentId}&role=${role}`
  try {
    // Get the cached data if TTL is not expired
    const cachedData = JSON.parse(sessionStorage.getItem(cacheKey))
    if (cachedData && Date.now() - cachedData.timestamp <= 3600000) {
      // 1 hour in milliseconds
      return cachedData.data
    } else if (cachedData) {
      // Remove the entry from cache due to expired TTL
      sessionStorage.removeItem(cacheKey)
    }

    console.log(queryParams)
    let url = `${API_ENDPOINT}/v1/users` + queryParams

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      return await response.json().then(function (result) {
        // Store user data in cache with timestamp
        const cachedItem = {
          data: result,
          timestamp: Date.now(),
        }
        sessionStorage.setItem(cacheKey, JSON.stringify(cachedItem))
        return result
      })
    } else {
      console.log('API request failed with status:', response.status)
      return []
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    return []
  }
}

/**
 * GET /v1/users/:userId
 * @param authToken
 * @returns Get user by userId
 */
export const getUserById = async (userId: string, authToken: string, cache = true) => {
  const cacheKey = `user_${userId}`
  try {
    if (cache) {
      // Get the cached data if TTL is not expired
      const cachedData = JSON.parse(sessionStorage.getItem(cacheKey))
      if (cachedData && Date.now() - cachedData.timestamp <= 3600000) {
        // 1 hour in milliseconds
        return cachedData.data
      } else if (cachedData) {
        // Remove the entry from cache due to expired TTL
        sessionStorage.removeItem(cacheKey)
      }
    } else {
      sessionStorage.removeItem(cacheKey)
    }

    const response = await fetch(`${API_ENDPOINT}/v1/users/${userId}`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json', // Adjust headers as needed
      },
    })

    if (response.ok) {
      const result = await response.json()

      // Store user data in cache with timestamp
      const cachedItem = {
        data: result,
        timestamp: Date.now(),
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(cachedItem))
      return result
      // Process the API response as needed
    } else {
      console.log('API request failed with status:', response.status)
      // Handle error scenarios
      return {}
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    // Handle error scenarios
    return {}
  }
}

/**
 * POST /v1/users/:userId
 * @param authToken
 * @returns Update user by userId
 */
export const updateUserById = async (userId: string, auth: IAuth, userInfo: UserInfo) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/users/${userId}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: auth.sessionInfo?.idToken!,
        'Content-Type': 'application/json', // Adjust headers as needed
      },
      body: JSON.stringify(userInfo),
    })

    if (response.ok) {
      // Successfully sent data to API

      // Update cache after successful update
      let cacheKey = ''
      const role = auth.userInfo!['role']
      if (role === 'staff' || role === 'admin') {
        // If the login user is staff/admin, the UserInfo being updated here is one of
        // its linked students. We need to correctly update its cache entry
        cacheKey = `user_${auth.userId}`
        const pre_cachedItem = JSON.parse(sessionStorage.getItem(cacheKey))
        const updatedStudents = pre_cachedItem.data['students'].map((studentProfile) => {
          if (studentProfile.username === userId) {
            // Update the student profile with data from cachedItem
            return { ...studentProfile, ...userInfo }
          }
          return studentProfile
        })

        // Update the students array in pre_cachedItem with the updatedStudents array
        pre_cachedItem.data['students'] = updatedStudents

        // Update the timestamp in cachedItem
        const updatedCachedItem = {
          data: pre_cachedItem.data,
          timestamp: Date.now(),
        }

        // Store the updatedCachedItem back in sessionStorage
        sessionStorage.setItem(cacheKey, JSON.stringify(updatedCachedItem))
      } else {
        // This is a student account, let's just do a update
        cacheKey = `user_${userId}`
        const cachedItem = {
          data: userInfo,
          timestamp: Date.now(),
        }
        sessionStorage.setItem(cacheKey, JSON.stringify(cachedItem))
      }
      return response
    } else {
      console.log('API request failed with status:', response.status)
      // Handle error scenarios
      return response
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    // Handle error scenarios
    return error
  }
}

/**
 * GET /v1/users/:userId/grades
 * @param authToken
 * @returns Get all available grade records of a student
 */
export const getGradeById = async (userId: string, authToken: string) => {
  const cacheKey = `grades_${userId}`
  try {
    // Get the cached data if TTL is not expired
    const cachedData = JSON.parse(sessionStorage.getItem(cacheKey))
    if (cachedData && Date.now() - cachedData.timestamp <= 3600000) {
      // 1 hour in milliseconds
      return cachedData.data
    } else if (cachedData) {
      // Remove the entry from cache due to expired TTL
      sessionStorage.removeItem(cacheKey)
    }

    const response = await fetch(`${API_ENDPOINT}/v1/users/${userId}/grades`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json', // Adjust headers as needed
      },
    })

    if (response.ok) {
      const clonedResponse = response.clone() // Clone the response
      try {
        const result = await response.json()

        // Store grade data in cache with timestamp
        const cachedItem = {
          data: result,
          timestamp: Date.now(),
        }
        sessionStorage.setItem(cacheKey, JSON.stringify(cachedItem))
        return result
      } catch (error) {
        const responseBody = await clonedResponse.text() // Get the response body as text
        console.log('Error parsing JSON response:', responseBody)
        return {}
      }
    } else {
      console.log('API request failed with status:', response.status)
      return {}
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    return {}
  }
}

/**
 * POST /v1/users/:userId/grades
 * @param authToken
 * @returns Update all grade records of a student
 */
export const updateGradeById = async (userId: string, auth: IAuth, gradeInfo: GradeInfo) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/users/${userId}/grades`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: auth.sessionInfo?.idToken!,
        'Content-Type': 'application/json', // Adjust headers as needed
      },
      body: JSON.stringify(gradeInfo),
    })

    if (response.ok) {
      // Successfully sent data to API

      // Update cache after successful update
      const cacheKey = `grades_${userId}`
      const cachedItem = {
        data: gradeInfo,
        timestamp: Date.now(),
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(cachedItem))
      return response
    } else {
      console.log('API request failed with status:', response.status)
      // Handle error scenarios
      return response
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    // Handle error scenarios
    return error
  }
}

/**
 * GET /v1/users/:userId/events
 * @param authToken
 * @returns Get all available events records of a student
 */
export const getEventsById = async (userId: string, authToken: string) => {
  const cacheKey = `events_${userId}`
  try {
    // Get the cached data if TTL is not expired
    const cachedData = JSON.parse(sessionStorage.getItem(cacheKey))
    if (cachedData && Date.now() - cachedData.timestamp <= 3600000) {
      // 1 hour in milliseconds 3600000
      return cachedData.data
    } else if (cachedData) {
      // Remove the entry from cache due to expired TTL
      sessionStorage.removeItem(cacheKey)
    }

    const response = await fetch(`${API_ENDPOINT}/v1/users/${userId}/events`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json', // Adjust headers as needed
      },
    })

    if (response.ok) {
      const clonedResponse = response.clone() // Clone the response
      try {
        const result = await response.json()

        // Store feedback data in cache with timestamp
        const cachedItem = {
          data: result['events'],
          timestamp: Date.now(),
        }
        sessionStorage.setItem(cacheKey, JSON.stringify(cachedItem))
        return result['events']
      } catch (error) {
        const responseBody = await clonedResponse.text() // Get the response body as text
        console.log('Error parsing JSON response:', error)
        console.log('Response body:', responseBody)
        return []
      }
    } else {
      console.log('API request failed with status:', response.status)
      return []
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    return []
  }
}

/**
 * POST /v1/users/:userId/events
 * @param authToken
 * @returns Update all events records of a student
 */
export const updateEventsById = async (userId: string, auth: IAuth, eventsInfo: EventsInfo[]) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/users/${userId}/events`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: auth.sessionInfo?.idToken!,
        'Content-Type': 'application/json', // Adjust headers as needed
      },
      body: JSON.stringify(eventsInfo),
    })

    if (response.ok) {
      // Successfully sent data to API

      // Update cache after successful update
      const cacheKey = `events_${userId}`
      const cachedItem = {
        data: eventsInfo,
        timestamp: Date.now(),
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(cachedItem))
      return response
    } else {
      console.log('API request failed with status:', response.status)
      // Handle error scenarios
      return response
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    // Handle error scenarios
    return error
  }
}

/**
 * GET /v1/users/:userId/feedbacks
 * @param authToken
 * @returns Get all available feedback records of a student
 */
export const getFeedbackById = async (userId: string, authToken: string) => {
  const cacheKey = `feedbacks_${userId}`
  try {
    // Get the cached data if TTL is not expired
    const cachedData = JSON.parse(sessionStorage.getItem(cacheKey))
    if (cachedData && Date.now() - cachedData.timestamp <= 3600000) {
      // 1 hour in milliseconds 3600000
      return cachedData.data
    } else if (cachedData) {
      // Remove the entry from cache due to expired TTL
      sessionStorage.removeItem(cacheKey)
    }

    const response = await fetch(`${API_ENDPOINT}/v1/users/${userId}/feedbacks`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json', // Adjust headers as needed
      },
    })

    if (response.ok) {
      const clonedResponse = response.clone() // Clone the response
      try {
        const result = await response.json()

        // Store feedback data in cache with timestamp
        const cachedItem = {
          data: result['feedback'],
          timestamp: Date.now(),
        }
        sessionStorage.setItem(cacheKey, JSON.stringify(cachedItem))
        return result['feedback']
      } catch (error) {
        const responseBody = await clonedResponse.text() // Get the response body as text
        console.log('Error parsing JSON response:', error)
        console.log('Response body:', responseBody)
        return []
      }
    } else {
      console.log('API request failed with status:', response.status)
      return []
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    return []
  }
}

/**
 * POST /v1/users/:userId/feedbacks
 * @param authToken
 * @returns Update all feedback records of a student
 */
export const updateFeedbackById = async (userId: string, auth: IAuth, feedbackInfo: FeedbackInfo[]) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/users/${userId}/feedbacks`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: auth.sessionInfo?.idToken!,
        'Content-Type': 'application/json', // Adjust headers as needed
      },
      body: JSON.stringify(feedbackInfo),
    })

    if (response.ok) {
      // Successfully sent data to API

      // Update cache after successful update
      const cacheKey = `feedbacks_${userId}`
      const cachedItem = {
        data: feedbackInfo,
        timestamp: Date.now(),
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(cachedItem))
      return response
    } else {
      console.log('API request failed with status:', response.status)
      // Handle error scenarios
      return response
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    // Handle error scenarios
    return error
  }
}

/**
 * POST /v1/users/:userId/actions
 * @param authToken
 * @returns Update all action records of a user
 */
export const updateActionsById = async (userId: string, authToken: string, actionsInfo: ActionInfo) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/users/${userId}/actions`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json', // Adjust headers as needed
      },
      body: JSON.stringify(actionsInfo),
    })

    if (response.ok) {
      // Successfully sent data to API
      return response
    } else {
      console.log('API request failed with status:', response.status)
      // Handle error scenarios
      return response
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    // Handle error scenarios
    return error
  }
}

export const sendRequestById = async (userId: string, authToken: string, title: string, subject: string) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/request`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json', // Adjust headers as needed
      },
      body: JSON.stringify({ title: title, subject: subject, userId: userId }),
    })

    console.log(response)
    if (response.ok) {
      // Successfully sent data to API
      console.log('Sent out request')

      return response
    } else {
      console.log('API request failed with status:', response.status)
      // Handle error scenarios
      return response
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    // Handle error scenarios
    return error
  }
}

export const createUserProfile = async (authToken: string, attributes: {}) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/create`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json', // Adjust headers as needed
      },
      body: JSON.stringify(attributes),
    })

    if (response.ok) {
      // Successfully sent data to API
      return response
    } else {
      console.log('API request failed with status:', response.status)
      // Handle error scenarios
      return response
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    // Handle error scenarios
    return error
  }
}

/**
 * GET /v1/homestays
 * @param authToken
 * @returns List of homestays (returns all homestays)
 */
export const getHomestays = async (authToken: string) => {
  const cacheKey = `homestayList`
  try {
    // Get the cached data if TTL is not expired
    const cachedData = JSON.parse(sessionStorage.getItem(cacheKey))
    if (cachedData && Date.now() - cachedData.timestamp <= 3600000) {
      // 1 hour in milliseconds
      return cachedData.data
    } else if (cachedData) {
      // Remove the entry from cache due to expired TTL
      sessionStorage.removeItem(cacheKey)
    }

    let url = `${API_ENDPOINT}/v1/homestays`

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      return await response.json().then(function (result) {
        // Store user data in cache with timestamp
        const cachedItem = {
          data: result,
          timestamp: Date.now(),
        }
        sessionStorage.setItem(cacheKey, JSON.stringify(cachedItem))
        return result
      })
    } else {
      console.log('API request failed with status:', response.status)
      console.log('API request failed with response:', response)
      return []
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    return []
  }
}

/**
 * GET /v1/homestays/:email
 * @param authToken
 * @returns Get homestay by email
 */
export const getHomestayByEmail = async (email: string, authToken: string) => {
  const cacheKey = `hoemstay_${email}`
  try {
    // Get the cached data if TTL is not expired
    const cachedData = JSON.parse(sessionStorage.getItem(cacheKey))
    if (cachedData && Date.now() - cachedData.timestamp <= 3600000) {
      // 1 hour in milliseconds
      return cachedData.data
    } else if (cachedData) {
      // Remove the entry from cache due to expired TTL
      sessionStorage.removeItem(cacheKey)
    }

    const response = await fetch(`${API_ENDPOINT}/v1/homestays/${email}`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json', // Adjust headers as needed
      },
    })

    if (response.ok) {
      const result = await response.json()

      // Store user data in cache with timestamp
      const cachedItem = {
        data: result,
        timestamp: Date.now(),
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(cachedItem))
      return result
      // Process the API response as needed
    } else {
      console.log('API request failed with status:', response.status)
      // Handle error scenarios
      return {}
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    // Handle error scenarios
    return {}
  }
}

/**
 * POST /v1/homestays/:email
 * @param authToken
 * @returns Update homestay by email
 */
export const updateHomestayByEmail = async (email: string, auth: IAuth, homestayInfo: HomestayInfo) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/homestays/${email}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: auth.sessionInfo?.idToken!,
        'Content-Type': 'application/json', // Adjust headers as needed
      },
      body: JSON.stringify(homestayInfo),
    })

    if (response.ok) {
      // Update cache after successful update
      const cacheKey = `hoemstay_${email}`
      const cachedItem = {
        data: homestayInfo,
        timestamp: Date.now(),
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(cachedItem))

      // Also remove the cache for the whole homestayList, as we need a update
      const cacheKey_list = `homestayList`
      const cachedData_list = JSON.parse(sessionStorage.getItem(cacheKey_list))
      if (cachedData_list) {
        sessionStorage.removeItem(cacheKey_list)
      }

      return response
    } else {
      console.log('API request failed with status:', response.status)
      // Handle error scenarios
      return response
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    // Handle error scenarios
    return error
  }
}

export const processForm = async (authToken: string, file_path: string) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/process_form`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json', // Adjust headers as needed
      },
      body: JSON.stringify({ file_path: file_path }),
    })

    if (response.ok) {
      // Successfully sent data to API
      console.log('Processed profile form')

      return response
    } else {
      console.log('API request failed with status:', response.status)
      // Handle error scenarios
      return response
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    // Handle error scenarios
    return error
  }
}

/**
 * POST /v1/homestays
 * @param authToken
 * @param homestayInfo
 * @returns Create new homestay
 */
export const createHomestay = async (authToken: string, homestayInfo: HomestayInfo) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/homestays`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json', // Adjust headers as needed
      },
      body: JSON.stringify(homestayInfo),
    })

    if (response.ok) {
      // Add a new entry in the cache
      const cacheKey = `hoemstay_${homestayInfo.email}`
      const cachedItem = {
        data: homestayInfo,
        timestamp: Date.now(),
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(cachedItem))

      // Also remove the cache for the whole homestayList, as we need a update
      const cacheKey_list = `homestayList`
      const cachedData_list = JSON.parse(sessionStorage.getItem(cacheKey_list))
      if (cachedData_list) {
        sessionStorage.removeItem(cacheKey_list)
      }

      return response
    } else {
      console.log('API request failed with status:', response.status)
      // Handle error scenarios
      return response
    }
  } catch (error) {
    console.log('API request failed with error:', error)
    // Handle error scenarios
    return error
  }
}
