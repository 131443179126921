import { useContext } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { AuthContext } from 'contexts/authContext'
import titleImage from '../Materials/title.png'

import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { UserRole } from 'type/userInfo'

const useStyles = makeStyles({
  body: {
    color: 'black',
  },
  gridItem: {
    marginTop: 20,
    marginBottom: 15,
  },
  customColor: {
    color: '#8e8e8e',
    textAlign: 'right',
  },
  button: {
    backgroundColor: '#ffd40a',
    color: 'black',
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
})

const Header: React.FunctionComponent = () => {
  const classes = useStyles()

  const navigate = useNavigate()

  const auth = useContext(AuthContext)
  const email = localStorage.getItem('email')

  function signOutClicked() {
    // Clear entire session storage
    sessionStorage.clear()
    console.log('After clearing session storage:', JSON.parse(sessionStorage.getItem(`user_${auth.userId}`)))

    auth.signOut()
    navigate('/', { replace: true })
    localStorage.removeItem('navigation')
    localStorage.removeItem('history')
    localStorage.removeItem('email')
    localStorage.removeItem('name')
  }

  function changePassword() {
    navigate('/changepassword')
  }

  function goHome() {
    localStorage.removeItem('history')
    if (auth.userInfo!['role'] !== UserRole.Student) {
      navigate('/profiles', { replace: true })
    }
  }

  return (
    <>
      <Grid container className={classes.gridItem} alignItems="center">
        <Grid item sm={1} /> {/* Left "margin" */}
        <Grid item className={classes.body} justifyContent="flex-start" direction="column" xs={12} sm={5}>
          <Box>
            <img src={titleImage} width={'40%'} alt="title" onClick={goHome} />
          </Box>
        </Grid>
        <Grid
          item
          className={classes.body}
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-end"
          xs={12}
          sm={5}
        >
          <Box alignItems="flex-end">
            <Typography className={classes.customColor}>{email}</Typography>
            <Typography className={classes.customColor}>
              欢迎回来，{localStorage.getItem('name') ? localStorage.getItem('name') : auth.userInfo.name}
            </Typography>
            <Button
              className={classes.button}
              onClick={signOutClicked}
              style={{ float: 'right', marginTop: 10 }}
              variant="contained"
            >
              退出登录
            </Button>
            <Button
              className={classes.button}
              onClick={changePassword}
              style={{ marginRight: 20, marginTop: 10, float: 'right' }}
              variant="contained"
            >
              修改密码
            </Button>
          </Box>
        </Grid>
        <Grid item sm={1} /> {/* Right "margin" */}
      </Grid>
    </>
  )
}

export default Header
