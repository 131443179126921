import React from 'react'

import TextField from '@mui/material/TextField'

export const Email: React.FunctionComponent<{ emailIsValid: boolean; setEmail: (_: string) => void }> = ({
  emailIsValid,
  setEmail,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={emailIsValid ? 'Email' : 'Invalid Email'}
      error={!emailIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEmail(evt.target.value)
      }}
    />
  )
}

export const Password: React.FunctionComponent<{
  label: string
  passwordIsValid: boolean
  setPassword: (_: string) => void
  customMessage?: string
  onKeyDown?: (_: React.KeyboardEvent<HTMLDivElement>) => void
}> = ({ label, passwordIsValid, setPassword, customMessage, onKeyDown }) => {
  return (
    <TextField
      fullWidth
      type="password"
      variant="outlined"
      label={passwordIsValid ? label : customMessage ? customMessage : '密码至少8个字符'}
      onKeyDown={onKeyDown}
      error={!passwordIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPassword(evt.target.value)
      }}
    />
  )
}

export const Username: React.FunctionComponent<{ isEmailValid: boolean; setUsername: (_: string) => void }> = ({
  isEmailValid,
  setUsername,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={isEmailValid ? '邮箱' : '请检查邮箱格式'}
      error={!isEmailValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setUsername(evt.target.value)
      }}
    />
  )
}

export const Code: React.FunctionComponent<{ codeIsValid: boolean; setCode: (_: string) => void }> = ({
  codeIsValid,
  setCode,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={codeIsValid ? '六位验证码' : '验证码为六位数'}
      error={!codeIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCode(evt.target.value)
      }}
    />
  )
}
