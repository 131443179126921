export interface ActionInfo {
  username?: string
  email?: string
  name?: string
  role?: string
  action_data?: ActionData[]
}

export interface ActionData {
  action_type?: ActionTypeEnum
  time_stamp?: string
}

export enum ActionTypeEnum {
  LogIn = 'LogIn',
}
