// StudentTable.tsx

import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tabs,
  Tab,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { AuthContext } from 'contexts/authContext'
import useErrorHandling from './../../hooks/useErrorHandling'
import closeImage from '../Materials/close.png'
import './../../App.css'
import { UserRole } from 'type/userInfo'
import { getHomestays, createHomestay } from 'libs/route'
import { homestayProfileFields, HomestayInfo } from 'type/homestayInfo'
import { secondarySchools, studentProfileFields, parentProfileFields } from 'type/userInfo'
import { createUserProfile, processForm } from 'libs/route'
import { uploadPublicFile } from 'libs/s3'

// Create custom styles using makeStyles
const useStyles = makeStyles({
  table: {
    width: '80%',
    margin: '20px auto',
    borderCollapse: 'collapse',
  },
  header: {
    backgroundColor: '#f2f2f2',
    fontWeight: 'bold',
  },
  row: {
    '&:nth-child(odd)': {
      backgroundColor: '#f9f9f9',
    },
  },
  cell: {
    padding: '8px',
    textAlign: 'left',
    border: '1px solid #ddd',
  },
  button: {
    backgroundColor: '#ffd40a',
    color: 'black',
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
  searchBar: {
    margin: '10px',
    marginTop: '2vh',
    marginLeft: '5vw',
    width: '50vw',
  },
  clearSearch: {
    width: '15vw',
    marginLeft: '1vw',
    color: '#8e8e8e',
    '&:hover': { cursor: 'pointer' },
  },
  menu: {
    backgroundColor: '#ffd40a',
    color: 'black',
    boxShadow: '4px 0px 5px 2px rgba(0, 0, 0, .1)',
    padding: '7px',
    height: '50px',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  createFormContainer: {
    background: 'white',
    padding: 50,
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    width: '80%',
    height: '80%',
    overflow: 'auto',
    borderRadius: 10,
  },
  importFormContainer: {
    background: 'white',
    padding: 50,
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    width: '50%',
    maxWidth: 500,
    height: '60%',
    maxHeight: 400,
    overflow: 'auto',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
  input: {
    width: '20vw',
  },
  styledDateInput: {
    padding: '8px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '10px',
    marginBottom: '10px',
    width: '20vw',
  },
})

export default function ProfileListPage() {
  // Use the custom styles
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const [user, setUser] = useState({})
  const [students, setStudents] = useState([])
  const [filteredStudents, setFilteredStudents] = useState([])
  const fields = ['name', 'ename', 'email', 'secondary_school']
  const auth = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const { error, handleError, clearError } = useErrorHandling()

  const [searchValue, setSearchValue] = useState('')

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value)
  }

  const [selectedValue, setSelectedValue] = useState('all-current')

  // Homestay create
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [showImportForm, setShowImportForm] = useState(false)

  const [newEntryName, setNewEntryName] = useState('')
  const [newEntryEmail, setNewEntryEmail] = useState('')
  const [newEntryAddress, setNewEntryAddress] = useState('')
  const [newEntryLicense, setNewEntryLicense] = useState('')
  const [newEntryRole, setNewEntryRole] = useState('')
  const [newEntryEName, setNewEntryEName] = useState('')
  const [newEntryGender, setNewEntryGender] = useState('')
  const [newEntrySchool, setNewEntrySchool] = useState('')
  const [newEntryBirthDate, setNewEntryBirthDate] = useState('')
  const [newEntryStartDate, setNewEntryStartDate] = useState('')
  const [newEntryGraduation, setNewEntryGraduation] = useState('')
  const [newEntryPen, setNewEntryPen] = useState('')
  const [newEntryMedical, setNewewEntryMedical] = useState('')
  const [newEntryUniversity, setNewEntryUniversity] = useState('')
  const [newEntryPhone, setNewEntryPhone] = useState('')
  const [newEntryQQ, setNewEntryQQ] = useState('')
  const [newEntryWechat, setNewEntryWechat] = useState('')
  const [newEntryManager, setNewEntryManager] = useState('')
  const [newEntryCounsellor, setNewEntryCounsellor] = useState('')
  const [newEntryCustodian, setNewEntryCustodian] = useState('')
  const [newEntryStudyPermit, setNewEntryStudyPermit] = useState('')
  const [newEntryPassport, setNewEntryPassport] = useState('')
  const [newEntryTRV, setNewEntryTRV] = useState('')
  const [newEntryRelationship, setNewEntryRelationship] = useState('')
  // Students field get passed to API as a string concatenated by commas
  // This list serves as an intermediate before the newEntryStudentsString string is formed
  const [newEntryStudents, setNewEntryStudents] = useState<string[]>([])
  const [newEntryStudentsString, setNewEntryStudentsString] = useState('')
  const [newEntryTeacher, setNewEntryTeacherString] = useState<string>('')

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }
  const [homestays, setHomestays] = useState([])

  // Update the initial selected tab based on location state
  const initialSelectedTab = sessionStorage.getItem('selectedTab') || 'student'
  const [selectedTab, setSelectedTab] = useState(
    location.state && location.state.selectedTab ? location.state.selectedTab : initialSelectedTab
  )

  useEffect(() => {
    localStorage.setItem('history', `${location.pathname}`)
  }, [location])

  // Handle the click event for the 'View Details' button
  const handleViewDetails = (index: number) => {
    // Navigate to the student detail page
    navigate(`/profile/${index}`)
    localStorage.removeItem('navigation')
  }

  // Handle the click event for the 'View Details' button for homestay
  const handleHomestayViewDetails = (index: number) => {
    // Navigate to the student or homestay detail page based on the selected tab
    navigate(`/homestay/${index}`)
    localStorage.removeItem('navigation')
  }

  const handleClosePopup = () => {
    clearError()
  }

  const [selectedFile, setSelectedFile] = useState(null)
  const fileInputRef = useRef(null)
  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const filterSearch = (searchString: string, keys: string[], objects: any[]) => {
    const regex = new RegExp(searchString, 'i')

    return objects.filter((obj) => {
      return keys.some((key) => {
        const value = obj[key]
        if (typeof value === 'string' || typeof value === 'number') {
          return regex.test(value.toString())
        }
        return false
      })
    })
  }

  const clearSearchContent = () => {
    setSearchValue('')
    setSelectedValue('all-current')
    const today = new Date();
    setFilteredStudents(students.filter((student) => {
      const graduationDate = student.graduation_date ? new Date(student.graduation_date) : null;
      return !graduationDate || graduationDate > today;
    }));
  }

  const constructUserList = () => {
    return (
      <>
        <Grid container>
          <Grid container>
            <Box className={classes.searchBar} alignItems="center" display="flex">
              <TextField
                value={searchValue}
                onChange={handleSearchChange}
                placeholder="搜索"
                variant="outlined"
                margin="dense"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl className={classes.input} variant="outlined" margin="dense" style={{ marginLeft: 20 }}>
                <InputLabel htmlFor="dropdown-selector">搜索范围</InputLabel>
                <Select
                  label="Options"
                  id="dropdown-selector"
                  value={selectedValue}
                  defaultValue='all-current'
                  onChange={(e) => setSelectedValue(e.target.value)}
                >
                  <MenuItem value="all-current">全部（在读）</MenuItem>
                  <MenuItem value="all">全部（包括毕业）</MenuItem>
                  <MenuItem value="name">姓名</MenuItem>
                  <MenuItem value="ename">英文名</MenuItem>
                  <MenuItem value="email">邮箱</MenuItem>
                  <MenuItem value="secondary_school">学校</MenuItem>
                  {/* Add more MenuItems as needed */}
                </Select>
              </FormControl>
              <Button
                className={classes.button}
                style={{ marginLeft: 20 }}
                variant="contained"
                onClick={() => {
                  if (searchValue) {
                    if (selectedValue && (selectedValue !== 'all' && selectedValue !== 'all-current')) {
                      setFilteredStudents(filterSearch(searchValue, [selectedValue], students))
                    } else {
                      setFilteredStudents(filterSearch(searchValue, fields, students))
                    }
                  } else {
                    if (selectedValue === 'all-current') {
                      const today = new Date();
                      setFilteredStudents(students.filter((student) => {
                        const graduationDate = student.graduation_date ? new Date(student.graduation_date) : null;
                        return !graduationDate || graduationDate > today;
                      }));
                    } else {
                      setFilteredStudents(students)
                    }
                  }
                }}
              >
                搜索
              </Button>
              <Typography className={classes.clearSearch} onClick={clearSearchContent}>
                清除搜索
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {!!filteredStudents.length && (
          <table id={'profileListTable'} className={classes.table}>
            <thead>
              <tr className={classes.header}>
                <th className={classes.cell}>姓名</th>
                <th className={classes.cell}>英文名</th>
                <th className={classes.cell}>邮箱</th>
                <th className={classes.cell}>学校</th>
                <th className={classes.cell}>更多</th>
              </tr>
            </thead>
            <tbody>
              {students.map(
                (student, index) =>
                  filteredStudents.includes(student) && (
                    <tr key={student.username} className={classes.row}>
                      <td id={'name'} className={classes.cell}>
                        {student.name}
                      </td>
                      <td className={classes.cell}>{student.ename}</td>
                      <td className={classes.cell}>{student.email}</td>
                      <td className={classes.cell}>{student.secondary_school}</td>
                      <td className={classes.cell}>
                        <Button
                          className={classes.button}
                          onClick={() => handleViewDetails(index)}
                          id={'viewDetails'}
                          variant="contained"
                          style={{ float: 'left' }}
                        >
                          查看详情
                        </Button>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        )}
        {showCreateForm && (
          <div className={classes.overlay}>
            <div className={classes.createFormContainer}>
              <img
                className={classes.hover}
                src={closeImage}
                style={{ float: 'right', marginTop: -20, marginRight: -10 }}
                width={'25px'}
                alt="cover"
                onClick={() => {
                  setShowCreateForm(false)
                  cleanUpNewEntry()
                }}
              />
              <h2>添加人员</h2>
              <Typography style={{ color: '#8e8e8e' }}>标注*为必填项</Typography>
              <form onSubmit={handleCreateSubmit}>
                {renderRoleInput()}
                {renderLinkStudentsInput()}
                {renderAllInputs()}
                <br />
                <br />
                <Button
                  className={classes.button}
                  style={{ float: 'left' }}
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  确认添加
                </Button>
              </form>
            </div>
          </div>
        )}
        {showImportForm && (
          <div className={classes.overlay}>
            <div className={classes.importFormContainer}>
              <img
                className={classes.hover}
                src={closeImage}
                style={{ float: 'right', marginTop: -20, marginRight: -10 }}
                width={'25px'}
                alt="cover"
                onClick={() => {
                  setShowImportForm(false)
                  setSelectedFile(null)
                }}
              />
              <h2>导入表格</h2>
              <form onSubmit={handleImportSubmit}>
                {renderFileUpload()}
                <br />
                {!selectedFile && <Typography>目前支持的上传文件格式：csv</Typography>}
                {selectedFile && (
                  <Grid>
                    <Typography>已选择：{selectedFile.name}</Typography>
                    <Typography
                      className={classes.hover}
                      style={{ color: 'red' }}
                      onClick={() => {
                        setSelectedFile(null)
                      }}
                    >
                      删除
                    </Typography>
                  </Grid>
                )}
                <br />
                <Button
                  className={classes.button}
                  style={{ float: 'left' }}
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  确认添加
                </Button>
              </form>
            </div>
          </div>
        )}
      </>
    )
  }

  const constructHomestayList = () => {
    return (
      <>
        {!!homestays.length && (
          <table className={classes.table}>
            <thead>
              <tr className={classes.header}>
                <th className={classes.cell}>姓名</th>
                <th className={classes.cell}>邮箱</th>
                <th className={classes.cell}>更多</th>
              </tr>
            </thead>
            <tbody>
              {homestays.map((homestay, index) => (
                <tr key={homestay.email} className={classes.row}>
                  <td className={classes.cell}>{homestay.name}</td>
                  <td className={classes.cell}>{homestay.email}</td>
                  <td className={classes.cell}>
                    <Button
                      className={classes.button}
                      onClick={() => handleHomestayViewDetails(index)}
                      id={'viewDetails'}
                      variant="contained"
                      style={{ float: 'left' }}
                    >
                      查看详情
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {showCreateForm && (
          <div key="createForm" className={classes.overlay}>
            <div className={classes.createFormContainer}>
              <img
                className={classes.hover}
                src={closeImage}
                style={{ float: 'right', marginTop: -20, marginRight: -10 }}
                width={'25px'}
                alt="cover"
                onClick={() => {
                  setShowCreateForm(false)
                  cleanUpHomesatyNewEntry()
                }}
              />
              <h2 key="createFormTitle">添加住家</h2>
              <form onSubmit={handleHomestayCreateSubmit}>
                {renderAllHomestayInputs()}
                <br />
                <Typography style={{ color: '#8e8e8e' }}>标注*为必填项</Typography>
                <br />
                <Button
                  className={classes.button}
                  style={{ float: 'left' }}
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  确认添加
                </Button>
              </form>
            </div>
          </div>
        )}
      </>
    )
  }

  // Homestay-related functions
  // Homestay: fetch
  const fetchHomestayData = async () => {
    try {
      setIsLoading(true)
      const homestayData = await getHomestays(auth.sessionInfo?.idToken)
      setHomestays(homestayData)
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching homestay data:', error)
    }
  }

  // Homestay: create a new entry
  const cleanUpHomesatyNewEntry = () => {
    Object.keys(homestayProfileFields).map((attr) => {
      if (Object.keys(homestayAttrNames).includes(attr)) {
        return homestayAttrSetters[attr]('')
      }
      return false
    })
  }

  const handleHomestayCreateSubmit = async () => {
    try {
      setIsLoading(true)

      // Construct homestayInfo
      const homestayInfo: HomestayInfo = {}
      for (const key in homestayAttrNames) {
        if (homestayAttrNames.hasOwnProperty(key)) {
          homestayInfo[key] = homestayAttrNames[key]
        }
      }

      const response = await createHomestay(auth.sessionInfo?.idToken, homestayInfo)

      if (response.status === 200) {
        alert('住家创建成功')
        setShowCreateForm(false)
        cleanUpHomesatyNewEntry()

        // Save the selected tab to localStorage
        sessionStorage.setItem('selectedTab', 'homestay')
        // Trigger a page refresh
        window.location.reload()
      } else {
        alert(`住家创建失败，请重试`)
      }

      setIsLoading(false)
    } catch (error) {
      console.error('Error sending inquiry:', error)
      setIsLoading(false)
      alert('住家创建失败，请重试')
    }
  }

  const renderHomestayCreateProfileButton = () => {
    return (
      <Button
        className={classes.button}
        onClick={() => setShowCreateForm(true)}
        id={'create'}
        style={{ marginRight: '1vw' }}
        variant="contained"
      >
        添加住家
      </Button>
    )
  }

  const homestayAttrNames = {
    name: newEntryName,
    email: newEntryEmail,
    phone_number: newEntryPhone,
    address: newEntryAddress,
    license_number: newEntryLicense,
  }

  const homestayAttrSetters = {
    name: setNewEntryName,
    email: setNewEntryEmail,
    phone_number: setNewEntryPhone,
    address: setNewEntryAddress,
    license_number: setNewEntryLicense,
  }

  const requiredAttrs = ['email', 'name']

  const renderAllHomestayInputs = () => {
    return Object.keys(homestayProfileFields).map((attr) => {
      if (Object.keys(homestayAttrNames).includes(attr)) {
        return homestayInputComponent(attr)
      }
      return false
    })
  }

  const homestayInputComponent = (attr: string) => {
    if (requiredAttrs.includes(attr)) {
      return (
        <Grid container alignItems="center">
          <Grid item direction="column" xs={2}>
            <Typography align="left">{homestayProfileFields[attr]['clabel']}*</Typography>
          </Grid>
          <Grid item direction="column" xs={4}>
            <TextField
              className={classes.input}
              value={homestayAttrNames[attr]}
              margin="dense"
              onChange={(e) => homestayAttrSetters[attr](e.target.value)}
              required
            />
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container alignItems="center">
          <Grid item direction="column" xs={2}>
            <Typography align="left">{homestayProfileFields[attr]['clabel']}</Typography>
          </Grid>
          <Grid item direction="column" xs={4}>
            <TextField
              className={classes.input}
              value={homestayAttrNames[attr]}
              margin="dense"
              onChange={(e) => homestayAttrSetters[attr](e.target.value)}
            />
          </Grid>
        </Grid>
      )
    }
  }

  useEffect(() => {
    const userInfo = auth.userInfo!
    setUser(userInfo)

    // Retrieve the list of students from userInfo
    const role = userInfo.role
    if (!role) {
      return
    }

    if (!userInfo.students) {
      if (role === UserRole.Student) {
        handleError(`该登录用户为学生账户，无权限访问该页面，如果该账户为非学生账户，请联系管理员`)
      } else {
        const roleDisplay = role === UserRole.Parent ? '家长' : '负责人'
        handleError(`该登录用户为${roleDisplay}账户，但并未有学生账户关联，请联系管理员`)
      }
    } else {
      if (selectedValue === 'all-current') {
        const today = new Date();
        setFilteredStudents(userInfo.students.filter((student) => {
          const graduationDate = student.graduation_date ? new Date(student.graduation_date) : null;
          return !graduationDate || graduationDate > today;
        }));
      } else {
        setFilteredStudents(userInfo.students)
      }
      setStudents(userInfo.students)
    }

    if (role === UserRole.Staff || role === UserRole.Admin) {
      fetchHomestayData()
    }

    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.userInfo])

  const cleanUpNewEntry = () => {
    attrSetters['role']('')
    Object.keys(studentProfileFields).map((attr) => {
      if (Object.keys(attrNames).includes(attr)) {
        return attrSetters[attr]('')
      }
      return false
    })
  }

  const handleCreateSubmit = async () => {
    try {
      if (newEntryRole === 'parent') {
        if (newEntryStudents.length < 1) {
          alert(`注册失败：家长账号必须至少有一名关联学生，请在'关联学生'选项，输入学生邮箱并完成按回车键`)
          return
        }
        setNewEntryStudentsString(newEntryStudents.join(','))
      }
      if (newEntryTeacher === "") {
        alert(`注册失败：学生账号必须关联一位生活老师，请在'生活老师邮箱'选项，输入生活老师邮箱`)
        return
      } 
      setIsLoading(true)
      const response = await createUserProfile(auth.sessionInfo?.idToken!, attrNames)
      const data = await response.json();
      setIsLoading(false)
      if (response.status === 200) {
        alert('成功创建用户')
        setShowCreateForm(false)
        cleanUpNewEntry()
        setNewEntryStudents([])
        auth.reload()
      } else {
        alert(`用户创建失败，请重试，error: ${data.message}`)
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error sending inquiry:', error)
      alert('用户创建失败，请重试')
    }
  }

  const handleImportSubmit = async () => {
    try {
      setIsLoading(true)
      const responses = await uploadPublicFile(user['username'], auth.sessionInfo?.idToken!, selectedFile)
      if (responses[0].status === 200) {
        await new Promise((resolve) => setTimeout(resolve, 3000))
        const processResponse = await processForm(auth.sessionInfo?.idToken!, responses[1])
        if (processResponse.status === 200) {
          setIsLoading(false)
          setShowImportForm(false)
          setSelectedFile(null)
          alert('表格导入成功')
        } else {
          setIsLoading(false)
          alert('表格处理失败')
        }
      } else {
        setIsLoading(false)
        alert('表格上传失败')
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error sending inquiry:', error)
      alert('表格导入失败，请重试')
    }
  }

  const renderCreateProfileButton = () => {
    if (user['role'] === UserRole.Student || user['role'] === UserRole.Parent) {
      return <div />
    }
    return (
      <Button
        className={classes.button}
        onClick={() => setShowCreateForm(true)}
        id={'create'}
        style={{ marginRight: '1vw' }}
        variant="contained"
      >
        添加人员
      </Button>
    )
  }

  const renderImportProfilesButton = () => {
    if (user['role'] === UserRole.Student || user['role'] === UserRole.Parent) {
      return <div />
    }
    return (
      <Button className={classes.button} onClick={() => setShowImportForm(true)} id={'import'} variant="contained">
        导入人员表
      </Button>
    )
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  const dateAttrs = [
    'secondary_startdate',
    'graduation_date',
    'studypermit_date',
    'birthdate',
    'passport_date',
    'trv_date',
  ]

  const attrNames = {
    role: newEntryRole,
    name: newEntryName,
    email: newEntryEmail,
    ename: newEntryEName,
    gender: newEntryGender,
    secondary_school: newEntrySchool,
    birthdate: newEntryBirthDate,
    secondary_startdate: newEntryStartDate,
    graduation_date: newEntryGraduation,
    pen_number: newEntryPen,
    medical_number: newEntryMedical,
    university: newEntryUniversity,
    phone_number: newEntryPhone,
    qq_number: newEntryQQ,
    wechat_id: newEntryWechat,
    general_manager: newEntryManager,
    counsellor: newEntryCounsellor,
    custodian: newEntryCustodian,
    studypermit_date: newEntryStudyPermit,
    passport_date: newEntryPassport,
    trv_date: newEntryTRV,
    relationship: newEntryRelationship,
    students: newEntryStudentsString,
    teacher: newEntryTeacher,
  }

  const attrSetters = {
    role: setNewEntryRole,
    name: setNewEntryName,
    email: setNewEntryEmail,
    ename: setNewEntryEName,
    gender: setNewEntryGender,
    secondary_school: setNewEntrySchool,
    birthdate: setNewEntryBirthDate,
    secondary_startdate: setNewEntryStartDate,
    graduation_date: setNewEntryGraduation,
    pen_number: setNewEntryPen,
    medical_number: setNewewEntryMedical,
    university: setNewEntryUniversity,
    phone_number: setNewEntryPhone,
    qq_number: setNewEntryQQ,
    wechat_id: setNewEntryWechat,
    general_manager: setNewEntryManager,
    counsellor: setNewEntryCounsellor,
    custodian: setNewEntryCustodian,
    studypermit_date: setNewEntryStudyPermit,
    passport_date: setNewEntryPassport,
    trv_date: setNewEntryTRV,
    relationship: setNewEntryRelationship,
    students: setNewEntryStudentsString,
    teacher: setNewEntryTeacherString,
  }

  const userRequiredAttrs = ['name', 'role', 'ename', 'email', 'gender']
  const requiredParentAttrs = ['name', 'role', 'email', 'relationship']

  const renderRoleInput = () => {
    return roleInputComponent('role')
  }
  const renderLinkStudentsInput = () => {
    return linkStudentsInputComponent()
  }

  const renderAllInputs = () => {
    if (newEntryRole === 'parent') {
      return Object.keys(parentProfileFields).map((attr) => {
        if (Object.keys(attrNames).includes(attr)) {
          return parentInputComponent(attr)
        }
        return false
      })
    }
    const fields = Object.keys(studentProfileFields).map((attr) => {
      if (Object.keys(attrNames).includes(attr)) {
        return studentInputComponent(attr)
      }
      return false
    })
    fields.splice(0, 0, studentInputComponent("teacher"))
    return fields
  }

  const renderFileUpload = () => {
    return (
      <Grid item>
        <Box>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={(e) => setSelectedFile(e.target.files[0])}
          />
          <Button className={classes.button} onClick={handleButtonClick} variant="contained">
            选择文件
          </Button>
        </Box>
      </Grid>
    )
  }

  const roleInputComponent = (attr: string) => {
    return (
      <Grid container alignItems="center">
        <Grid item direction="column" xs={2}>
          <Typography align="left">账号类型*</Typography>
        </Grid>
        <Grid item direction="column" xs={4}>
          <FormControl className={classes.input} variant="outlined" margin="dense" required>
            <InputLabel htmlFor="dropdown-selector">选择账号类型</InputLabel>
            <Select
              label="Options"
              id="dropdown-selector"
              value={attrNames[attr]}
              onChange={(e) => attrSetters[attr](e.target.value)}
            >
              <MenuItem value="student">学生</MenuItem>
              {/* <MenuItem value="parent">家长</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    )
  }

  const linkStudentsInputComponent = () => {
    if (newEntryRole === 'parent') {
      return (
        <Grid container alignItems="center">
          <Grid item direction="column" xs={2}>
            <Typography align="left">关联老师*</Typography>
            <Typography style={{ color: '#8e8e8e' }}>输入完成按回车</Typography>
          </Grid>
          <Grid item direction="column" xs={4}>
            <Autocomplete
              className={classes.input}
              multiple
              options={[]}
              freeSolo
              value={newEntryStudents}
              onChange={(e, newStudents) => setNewEntryStudents(newStudents)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip key={index} label={option} {...getTagProps({ index })} />)
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="学生邮箱" placeholder="输入完成按回车，可继续添加" />
              )}
            />
          </Grid>
        </Grid>
      )
    }
  }

  const studentInputComponent = (attr: string) => {
    if (attr === 'secondary_school') {
      return (
        <Grid container alignItems="center">
          <Grid item direction="column" xs={2}>
            <Typography align="left">{studentProfileFields[attr]['clabel']}*</Typography>
          </Grid>
          <Grid item direction="column" xs={4}>
            <FormControl className={classes.input} variant="outlined" margin="dense" required>
              <InputLabel htmlFor="dropdown-selector">选择一所学校</InputLabel>
              <Select
                label="Options"
                id="dropdown-selector"
                value={attrNames[attr]}
                onChange={(e) => attrSetters[attr](e.target.value)}
              >
                {secondarySchools.map((school) => (
                  <MenuItem value={school}>{school}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )
    } else if (attr === 'gender') {
      return (
        <Grid container alignItems="center">
          <Grid item direction="column" xs={2}>
            <Typography align="left">{studentProfileFields[attr]['clabel']}*</Typography>
          </Grid>
          <Grid item direction="column" xs={4}>
            <FormControl className={classes.input} variant="outlined" margin="dense" required>
              <InputLabel htmlFor="dropdown-selector">选择性别</InputLabel>
              <Select
                label="Options"
                id="dropdown-selector"
                value={attrNames[attr]}
                onChange={(e) => attrSetters[attr](e.target.value)}
              >
                <MenuItem value="Male">男</MenuItem>
                <MenuItem value="Female">女</MenuItem>
                <MenuItem value="Other">其他</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )
    } else if (dateAttrs.includes(attr)) {
      return (
        <Grid container alignItems="center">
          <Grid item direction="column" xs={2}>
            <Typography align="left">{studentProfileFields[attr]['clabel']}</Typography>
          </Grid>
          <Grid item direction="column" xs={4}>
            <input
              type="date"
              id="datepicker"
              className={classes.styledDateInput}
              onChange={(e) => attrSetters[attr](e.target.value)}
              value={attrNames[attr]}
            />
          </Grid>
        </Grid>
      )
    } else if (userRequiredAttrs.includes(attr)) {
      return (
        <Grid container alignItems="center">
          <Grid item direction="column" xs={2}>
            <Typography align="left">{studentProfileFields[attr]['clabel']}*</Typography>
          </Grid>
          <Grid item direction="column" xs={4}>
            <TextField
              className={classes.input}
              value={attrNames[attr]}
              margin="dense"
              onChange={(e) => attrSetters[attr](e.target.value)}
              required
            />
          </Grid>
        </Grid>
      )
    } else if (attr === "teacher") {
      return (
        <Grid container alignItems="center">
          <Grid item direction="column" xs={2}>
            <Typography align="left">{"生活老师邮箱*"}</Typography>
            <Typography style={{ color: '#8e8e8e' }}>关联该学生账户与生活老师账户</Typography>
          </Grid>
          <Grid item direction="column" xs={4}>
            <TextField
              className={classes.input}
              value={attrNames[attr]}
              margin="dense"
              onChange={(e) => attrSetters[attr](e.target.value)}
            />
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container alignItems="center">
          <Grid item direction="column" xs={2}>
            <Typography align="left">{studentProfileFields[attr]['clabel']}</Typography>
          </Grid>
          <Grid item direction="column" xs={4}>
            <TextField
              className={classes.input}
              value={attrNames[attr]}
              margin="dense"
              onChange={(e) => attrSetters[attr](e.target.value)}
            />
          </Grid>
        </Grid>
      )
    }
  }

  const parentInputComponent = (attr: string) => {
    if (attr === 'relationship') {
      return (
        <Grid container alignItems="center">
          <Grid item direction="column" xs={2}>
            <Typography align="left">{parentProfileFields[attr]['clabel']}*</Typography>
          </Grid>
          <Grid item direction="column" xs={4}>
            <FormControl className={classes.input} variant="outlined" margin="dense" required>
              <InputLabel htmlFor="dropdown-selector">选择关系</InputLabel>
              <Select
                label="Options"
                id="dropdown-selector"
                value={attrNames[attr]}
                onChange={(e) => attrSetters[attr](e.target.value)}
              >
                <MenuItem value="Father">父亲</MenuItem>
                <MenuItem value="Mother">母亲</MenuItem>
                <MenuItem value="Other">其他</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )
    } else if (requiredParentAttrs.includes(attr)) {
      return (
        <Grid container alignItems="center">
          <Grid item direction="column" xs={2}>
            <Typography align="left">{parentProfileFields[attr]['clabel']}*</Typography>
          </Grid>
          <Grid item direction="column" xs={4}>
            <TextField
              className={classes.input}
              value={attrNames[attr]}
              margin="dense"
              onChange={(e) => attrSetters[attr](e.target.value)}
              required
            />
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container alignItems="center">
          <Grid item direction="column" xs={2}>
            <Typography align="left">{parentProfileFields[attr]['clabel']}</Typography>
          </Grid>
          <Grid item direction="column" xs={4}>
            <TextField
              className={classes.input}
              value={attrNames[attr]}
              margin="dense"
              onChange={(e) => attrSetters[attr](e.target.value)}
            />
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <>
      <Grid container alignItems="center">
        <Grid container className={classes.menu} direction="column" justifyContent="center" alignItems="flex-end">
          <Box display="flex" flexDirection="row" alignItems="center" style={{ position: 'relative', right: '1vw' }}>
            {/* {selectedTab === 'homestay' && renderHomestayCreateProfileButton()} */}
            {selectedTab === 'student' && renderCreateProfileButton()}
          </Box>
        </Grid>
      </Grid>
      {!!error && (
        <div className="popup">
          <p>{error}</p>
          <button className={classes.button} onClick={handleClosePopup}>
            关闭
          </button>{' '}
        </div>
      )}
      {/* {(auth.userInfo.role === UserRole.Staff || auth.userInfo.role === UserRole.Admin) && (
        <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
          <Tab label="学生" value="student" />
          <Tab label="住家" value="homestay" />
        </Tabs>
      )} */}
      {selectedTab === 'student' && constructUserList()}

      {/* {selectedTab === 'homestay' && constructHomestayList()} */}
    </>
  )
}
