import { useContext, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { makeStyles } from '@mui/styles'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { AuthContext } from 'contexts/authContext'
import ProfileListPage from 'components/ProfileList/profileList'
import NavBar from 'components/ProfileDetail/NavBar/navbar'
import HomestayPage from 'components/ProfileDetail/Homestay/homestay'
import Header from './header'
import Footer from './footer'
import { UserRole } from 'type/userInfo'
import MessagingModal from 'components/Messaging/messagingModal'

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  title: {
    textAlign: 'center',
  },
  session: {
    width: '80vw',
    overflow: 'auto',
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  body: {
    backgroundColor: 'white',
    width: '100%',
    color: 'black',
    boxShadow: '4px 3px 5px 2px rgba(0, 0, 0, .1)',
    paddingBottom: '2vh',
    minHeight: '400px',
  },
}))

export default function Home({ index }: { index: number }) {
  const classes = useStyles()

  const navigate = useNavigate()

  const auth = useContext(AuthContext)
  const role = auth.userInfo!['role'] as UserRole

  useEffect(() => {
    const userInfo = auth.userInfo!

    if (localStorage.getItem('history')) {
      navigate(`${localStorage.getItem('history')}`, { replace: true })
    } else if (userInfo['role'] === UserRole.Student) {
      navigate('/profile', { replace: true })
    } else {
      navigate('/profiles', { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.userInfo])

  const renderContent = () => {
    if (index === 0) {
      return <ProfileListPage />
    } else if (index === 1) {
      return <NavBar key="profile/index" />
    } else if (index === 2) {
      return <NavBar key="profile/me" />
    } else if (index === 3) {
      return <HomestayPage key="homestay/index" />
    }
  }

  return (
    <div>
      {/* Hide messaging button until backend is implemented */}
      {/* {(role === UserRole.Admin || role === UserRole.Staff) && <MessagingModal />} */}
      <Grid className={classes.root}>
        <Header />
        <Grid container alignItems="center">
          <Grid item sm={1} /> {/* Left "margin" */}
          <Grid item direction="column" justifyContent="center" alignItems="center" xs={12} sm={10}>
            <Box alignItems="center" className={classes.body}>
              {renderContent()}
            </Box>
          </Grid>
          <Grid item sm={1} /> {/* Right "margin" */}
        </Grid>
        <Footer />
      </Grid>
    </div>
  )
}
