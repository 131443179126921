// S3FileUploaderDownloader.js

import React, { useContext, useState, useRef } from 'react'
import { uploadFile, downloadFile, listFiles, deleteFile } from 'libs/s3'
import { makeStyles } from '@mui/styles'
import { AuthContext } from 'contexts/authContext'
import Box from '@mui/material/Box'
import { Button, CircularProgress, Grid } from '@mui/material'
import { Typography } from '@mui/material'
import FileTypesComponent from './FileTypes'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 40,
    marginRight: 40,
    marginTop: 20,
  },
  uploadSection: {
    marginTop: 20,
    paddingBottom: 20,
    borderBottom: '1px solid #ccc',
  },
  verticalEntry: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginBottom: '8px',
  },
  fileEntry: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
    marginTop: 10,
  },
  input: {
    marginTop: '32px',
    marginBottom: '16px',
  },
  button: {
    backgroundColor: '#ffd40a',
    color: 'black',
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
}))

interface UploadOptions {
  label: string
  value: string
}

const S3FileUploaderDownloader = ({ userInfo, fileList, setFileList }) => {
  const classes = useStyles()
  const auth = useContext(AuthContext)
  const [selectedFile, setSelectedFile] = useState(null)
  const [files, setFiles] = useState(fileList ?? [])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState<UploadOptions | null>(null)

  const fileInputRef = useRef(null)

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const handleUpload = async () => {
    setIsLoading(true)
    if (!selectedFile) {
      alert('未选择上传文件')
      setIsLoading(false)
      return
    }
    if (!selectedOption) {
      alert('请选择上传文件类型')
      setIsLoading(false)
      return
    }
    if (files.map((file) => file.value).includes(selectedFile.name)) {
      const overwrite = window.confirm('文件已存在。是否覆盖？');
  
      if (!overwrite) {
        setIsLoading(false);
        return;
      }
    }
    const response = await uploadFile(userInfo.username, auth.sessionInfo?.idToken!, selectedFile, selectedOption.value)
    fetchFiles()
    if (response.status === 200) {
      alert('修改成功')
      setSelectedFile(null)
      setSelectedOption(null)
    } else {
      alert('修改失败')
    }
    setIsLoading(false)
  }

  const handleDownload = async (fileName, type) => {
    await downloadFile(userInfo.username, auth.sessionInfo?.idToken!, fileName, type)
  }

  const handleDelete = async (fileName, type) => {
    const isConfirmed = window.confirm(`您确定要删除文件：${fileName} 吗？`);
    if (!isConfirmed) {
      return; // Exit if the user cancels the confirmation
    }
  
    try {
      await deleteFile(userInfo.username, auth.sessionInfo?.idToken!, fileName, type);
      alert(`文件 ${fileName} 已成功删除`);
      window.location.reload();
    } catch (error) {
      console.error('Error deleting file:', error);
      alert(`删除文件 ${fileName} 失败`);
    }
  };

  const fetchFiles = async () => {
    const fileList = await listFiles(userInfo.username, auth.sessionInfo?.idToken!)
    setFiles(fileList ?? [])
    setFileList(fileList)
  }

  const filterFiles = (type) => {
    return files.filter((file) => file.type === type)
  }

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value
    const selectedLabel = uploadOptions.find((option) => option.value === selectedValue) || null
    setSelectedOption(selectedLabel)
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  const uploadOptions: UploadOptions[] = [
    { label: '教育局注册', value: 'registration' },
    { label: '协议', value: 'documents' },
    { label: '学生信息', value: 'info' },
    { label: '成绩单', value: 'transcript' },
    { label: '缴费凭证', value: 'payment' },
  ]

  return (
    <div className={classes.container}>
      {
        /* Upload Section */
        auth.userInfo.role === 'staff' || auth.userInfo.role === 'admin' ? (
          <Grid container direction="column" className={classes.uploadSection}>
            <Typography variant="h6">文件上传</Typography>
            <Grid item className={classes.fileEntry}>
              <Box>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                />
                <Button className={classes.button} onClick={handleButtonClick} variant="contained">
                  选择文件
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.verticalEntry}>
                {uploadOptions.map((option) => (
                  <label key={option.value}>
                    <input
                      type="radio"
                      value={option.value}
                      checked={selectedOption?.value === option.value}
                      onChange={handleOptionChange}
                    />
                    {option.label}
                  </label>
                ))}
                {selectedOption && <p>上传文件类型: {selectedOption.label}</p>}
                {selectedFile && <p>已选择：{selectedFile.name}</p>}
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Button className={classes.button} variant="contained" onClick={handleUpload}>
                  上传
                </Button>
              </Box>
            </Grid>
          </Grid>
        ) : null
      }
      <Grid>
        <FileTypesComponent files={filterFiles('registration')} title={'教育局注册'} handleDownload={handleDownload} handleDelete={handleDelete} />
        <FileTypesComponent files={filterFiles('documents')} title={'协议'} handleDownload={handleDownload} handleDelete={handleDelete} />
        <FileTypesComponent files={filterFiles('info')} title={'学生信息'} handleDownload={handleDownload} handleDelete={handleDelete} />
        <FileTypesComponent files={filterFiles('transcript')} title={'成绩单'} handleDownload={handleDownload} handleDelete={handleDelete} />
        <FileTypesComponent files={filterFiles('payment')} title={'缴费凭证'} handleDownload={handleDownload} handleDelete={handleDelete} />
      </Grid>
    </div>
  )
}

export default S3FileUploaderDownloader
