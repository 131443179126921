import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Button, TextField, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { secondarySchools } from 'type/userInfo'

const useStyles = makeStyles((theme) => ({
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
  },
  modal: {
    backgroundColor: 'white',
    width: '50%',
    padding: 10,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formGroup: {
    marginBottom: 20,
    maxWidth: 600,
    width: '100%',
  },
  label: {
    alignItems: 'center',
    marginBottom: 5,
  },
  input: {
    width: 300,
  },
  select: {
    padding: 10,
    borderRadius: 5,
    border: '1px solid #ccc',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: 'auto',
  },
  styledDateInput: {
    padding: '8px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '10px',
  },
  button: {
    backgroundColor: '#ffd40a',
    marginTop: 'auto',
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    color: 'black',
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
}))

const EditProfileModal = (input) => {
  const { content, updateContent, isOpen, closeModal, label, attr } = input
  const [newInput, setNewInput] = useState(content || '')
  const classes = useStyles()

  const onSubmit = (e) => {
    e.preventDefault()
    if (!newInput) {
      alert('输入不能为空')
      return
    }
    updateContent(newInput)
    closeModal()
  }

  const handleInputChange = (e) => {
    setNewInput(e.target.value)
  }

  const dateAttrs = [
    'secondary_startdate',
    'graduation_date',
    'studypermit_date',
    'birthdate',
    'passport_date',
    'trv_date',
  ]
  const inputComponent = (attr) => {
    if (dateAttrs.includes(attr)) {
      return (
        <input
          type="date"
          id="datepicker"
          className={classes.styledDateInput}
          onChange={handleInputChange}
          value={newInput}
        />
      )
    } else if (attr === 'secondary_school') {
      return (
        <form className={classes.formGroup}>
          <FormControl className={classes.input} variant="outlined" margin="dense" required>
            <InputLabel htmlFor="dropdown-selector">选择一所学校</InputLabel>
            <Select label="Options" id="dropdown-selector" value={newInput} onChange={handleInputChange}>
              {secondarySchools.map((school) => (
                <MenuItem value={school}>{school}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      )
    } else {
      return <TextField className={classes.input} margin="dense" value={newInput} onChange={handleInputChange} />
    }
  }

  return (
    <div>
      {isOpen && (
        <div className={classes.modalOverlay}>
          <div className={classes.modal}>
            <h2>修改信息</h2>
            <form className={classes.formGroup}>
              <Grid container direction="column" className={classes.label}>
                <Grid item>{label}</Grid>
                <Grid item>{inputComponent(attr)}</Grid>
              </Grid>
            </form>
            <div className={classes.buttonGroup}>
              <Button className={classes.button} variant="contained" onClick={onSubmit}>
                确认
              </Button>
              <Button className={classes.button} variant="contained" onClick={closeModal}>
                取消
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EditProfileModal
