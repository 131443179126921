import React, { useState, useContext } from 'react'

import { useNavigate } from 'react-router-dom'

import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

import { useValidUsername } from '../../hooks/useAuthHooks'
import { Username } from '../utils/authComponents'

import { AuthContext } from '../../contexts/authContext'

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
  text: {
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#ffd40a',
    color: 'black',
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
})

export default function RequestCode() {
  const classes = useStyles()

  const { username, setUsername, isEmailValid } = useValidUsername('')
  const [error, setError] = useState('')
  const [resetSent, setResetSent] = useState(false)

  const isValid = !isEmailValid || username.length === 0

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const sendCodeClicked = async () => {
    try {
      await authContext.sendCode(username)
      setResetSent(true)
      localStorage.setItem('requestCodeEmail', `${username}`)
    } catch (err) {
      setError(`Unknown user ${err}`)
    }
  }

  const emailSent = (
    <>
      <Box mt={1}>
        <Typography className={classes.text} variant="h6">{`验证码已发送至邮箱： ${username}`}</Typography>
      </Box>
      <Box mt={4}>
        <Button onClick={() => navigate('/forgotpassword')} color="primary" variant="contained">
          重置密码
        </Button>
      </Box>
    </>
  )

  const sendCode = (
    <>
      <Box width="80%" m={1}>
        <Username isEmailValid={isEmailValid} setUsername={setUsername} />
      </Box>
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      <Box mt={2}>
        <Grid container direction="row" justifyContent="center">
          <Box m={1}>
            <Button className={classes.button} variant="contained" onClick={() => navigate(-1)}>
              取消
            </Button>
          </Box>
          <Box m={1}>
            <Button className={classes.button} disabled={isValid} variant="contained" onClick={sendCodeClicked}>
              发送验证码
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  return (
    <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} container direction="row" justifyContent="center" alignItems="center" item>
        <Paper style={{ width: '100%', padding: 32 }}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Box m={2}>
              <Typography variant="h5">忘记密码</Typography>
            </Box>
            {resetSent ? emailSent : sendCode}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
