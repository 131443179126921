import React, { useState, useEffect, useContext } from 'react'

import { getUserById, updateActionsById } from '../libs/route'
import * as cognito from '../libs/cognito'
import { UserInfo } from 'type/userInfo'
import { ActionInfo, ActionTypeEnum } from 'type/actionInfo'

export enum AuthStatus {
  Loading,
  Reload,
  SignedIn,
  SignedOut,
}

interface SessionInfo {
  username?: string
  email?: string
  sub?: string
  accessToken?: string
  refreshToken?: string
  idToken?: string
}

export interface IAuth {
  sessionInfo?: SessionInfo
  userId?: string
  userInfo?: any
  attrInfo?: any
  authStatus?: AuthStatus
  signInWithEmail?: any
  signUpWithEmail?: any
  signOut?: any
  reload?: any
  verifyCode?: any
  getSession?: any
  sendCode?: any
  updateUserInfo?: any
  forgotPassword?: any
  changePassword?: any
  getAttributes?: any
  setAttribute?: any
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
  userId: '',
  userInfo: {},
}

type Props = {
  children?: React.ReactNode
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
  const [sessionInfo, setSessionInfo] = useState<SessionInfo>({})
  const [attrInfo, setAttrInfo] = useState([])
  const [userId, setUserId] = useState('')
  const [userInfo, setUserInfo] = useState<UserInfo>({})

  useEffect(() => {
    async function getSessionInfo() {
      try {
        const session: any = await getSession()
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
          idToken: session.idToken.jwtToken,
        })
        window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`)
        window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
        window.localStorage.setItem('idToken', `${session.idToken.jwtToken}`)
        // await setAttribute({ Name: 'website', Value: '' })
        const attr: any = await getAttributes()
        setAttrInfo(attr)
        const currentUserId = attr.find((a: any) => a.Name === 'sub').Value
        setUserId(currentUserId)
        setAuthStatus(AuthStatus.SignedIn)
        const userInfo = await getUserById(currentUserId, session.idToken.jwtToken, false)
        setUserInfo(userInfo)
        localStorage.setItem('name', `${userInfo.name}`)
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut)
        setUserInfo({})
      }
    }
    getSessionInfo()
  }, [setAuthStatus, authStatus])

  if (authStatus === AuthStatus.Loading) {
    return null
  }

  async function signInWithEmail(email: string, password: string) {
    try {
      await cognito.signInWithEmail(email, password)
      setAuthStatus(AuthStatus.SignedIn)
      trackLogInAction(userInfo)
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)
      throw err
    }
  }

  async function trackLogInAction(userInfo: UserInfo) {
    const actionData = [{ time_stamp: new Date().toISOString(), action_type: ActionTypeEnum.LogIn }]
    const newActionInfo: ActionInfo = {
      username: userInfo.username,
      email: userInfo.email,
      name: userInfo.name,
      role: userInfo.role,
      action_data: actionData,
    }
    await updateActionsById(userInfo.username, sessionInfo.idToken, newActionInfo)
  }

  async function signUpWithEmail(username: string, email: string, password: string) {
    try {
      await cognito.signUpUserWithEmail(username, email, password)
    } catch (err) {
      throw err
    }
  }

  function signOut() {
    cognito.signOut()
    setAuthStatus(AuthStatus.SignedOut)
  }

  function reload() {
    setTimeout(() => {}, 3000) // Delay for 3s
    setAuthStatus(AuthStatus.Reload)
  }

  async function updateUserInfo() {
    try {
      const userInfo = await getUserById(userId, sessionInfo.idToken)
      setUserInfo(userInfo)
    } catch (err) {
      throw err
    }
  }

  async function verifyCode(username: string, code: string) {
    try {
      await cognito.verifyCode(username, code)
    } catch (err) {
      throw err
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession()
      return session
    } catch (err) {
      throw err
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes()
      return attr
    } catch (err) {
      throw err
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await cognito.setAttribute(attr)
      return res
    } catch (err) {
      throw err
    }
  }

  async function sendCode(username: string) {
    try {
      await cognito.sendCode(username)
    } catch (err) {
      throw err
    }
  }

  async function forgotPassword(username: string, code: string, password: string) {
    try {
      await cognito.forgotPassword(username, code, password)
    } catch (err) {
      throw err
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await cognito.changePassword(oldPassword, newPassword)
    } catch (err) {
      throw err
    }
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    userId,
    userInfo,
    attrInfo,
    updateUserInfo,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    reload,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider
