import React, { useState, useContext } from 'react'
import Grid from '@mui/material/Grid'

import { makeStyles } from '@mui/styles'
import { Button, CircularProgress } from '@mui/material'
import GradeContentForm from './CourseGrade'
import { GradeInfo } from 'type/gradeInfo'
import { AuthContext } from 'contexts/authContext'
import { updateGradeById } from 'libs/route'
import { UserRole } from 'type/userInfo'
import { UserInfo } from 'type/userInfo'

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '4vh' },
  title: {
    textAlign: 'center',
  },
  subheader: {
    textAlign: 'left',
    marginLeft: 30,
  },
  content: {
    backgroundColor: '#FFFFFF',
    width: '95%',
    marginTop: 20,
    borderRadius: 10,
  },
  button: {
    backgroundColor: '#ffd40a',
    color: 'black',
    marginBottom: 20,
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
}))

const GradeSheetComponent = ({ userInfo, gradeInfo }: { userInfo: UserInfo; gradeInfo: GradeInfo }) => {
  const classes = useStyles()
  const auth = useContext(AuthContext)
  const gradeInfoCopy = JSON.parse(JSON.stringify(gradeInfo))
  const [isLoading, setIsLoading] = useState(false)

  const getGrade = () => {
    return (
      gradeInfoCopy?.grade_data?.map((grade) => {
        return JSON.parse(grade)
      }) ?? []
    )
  }

  const getGradeInCN = () => {
    return (
      gradeInfoCopy?.grade_in_china?.map((grade) => {
        return JSON.parse(grade)
      }) ?? []
    )
  }

  const onSaveClick = async () => {
    setIsLoading(true)
    const gradeData = grade.map((grade) => JSON.stringify(grade))
    const gradeInChinaData = gradeInChina.map((grade) => JSON.stringify(grade))
    gradeInfo.grade_data = gradeData
    gradeInfo.grade_in_china = gradeInChinaData

    if (
      JSON.stringify(getGradeInCN()) === JSON.stringify(gradeInChina) &&
      JSON.stringify(getGrade()) === JSON.stringify(grade)
    ) {
      alert('没有修改')
      setIsLoading(false)
      return
    }

    const response = await updateGradeById(gradeInfo.username, auth, gradeInfo)
    setIsLoading(false)
    if (response.status === 200) {
      alert('修改成功')
    } else {
      alert('修改失败')
    }
  }

  const [gradeInChina, setGradeInChina] = useState(getGradeInCN())
  const [grade, setGrade] = useState(getGrade())

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <Grid>
      <GradeContentForm contents={grade} setContents={setGrade} isGradeInChina={false} />
      {auth.userInfo!['role'] === UserRole.Parent || auth.userInfo!['role'] === UserRole.Student ? (
        <div />
      ) : (
        <Button
          className={classes.button}
          onClick={onSaveClick}
          variant="contained"
          style={{ marginTop: 40, float: 'left', marginLeft: 40 }}
        >
          保存修改
        </Button>
      )}
    </Grid>
  )
}

export default GradeSheetComponent
