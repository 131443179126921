import React, { useState, useContext } from 'react'

import { useNavigate } from 'react-router-dom'

import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'
import { Email, Password } from '../utils/authComponents'

import { AuthContext } from '../../contexts/authContext'

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
  button: {
    backgroundColor: '#ffd40a',
    color: 'black',
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
  hover: {
    letterSpacing: '0.1em',
    '&:hover': { cursor: 'pointer' },
  },
})

const SignIn: React.FunctionComponent<{}> = () => {
  const classes = useStyles()

  const { email, setEmail, emailIsValid } = useValidEmail('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [error, setError] = useState('')

  // const isValid = !emailIsValid || email.length === 0 || !passwordIsValid || password.length === 0

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const signInClicked = async () => {
    try {
      await authContext.signInWithEmail(email, password)
      localStorage.setItem('email', `${email}`)
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        navigate('verify')
      } else {
        setError(err.message)
      }
      localStorage.removeItem('email')
      localStorage.removeItem('name')
    }
  }

  const passwordResetClicked = async () => {
    navigate('requestcode')
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      signInClicked()
    }
  }

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Box>
        <Typography className={classes.hover} variant="h5">
          学生管理平台
        </Typography>
      </Box>
      <Box width="70%" m={1} id="email">
        <Email emailIsValid={emailIsValid} setEmail={setEmail} />
        {/* <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />{' '} */}
      </Box>
      <Box width="70%" id="password">
        <Password
          label="Password"
          passwordIsValid={passwordIsValid}
          setPassword={setPassword}
          onKeyDown={handleKeyDown}
        />
      </Box>
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>
      <Box>
        <Button onClick={signInClicked} className={classes.button} variant="contained">
          登录
        </Button>
      </Box>
      <Box onClick={passwordResetClicked} mt={2} id="forgetpassword">
        <Typography className={classes.hover} variant="body2">
          忘记密码？
        </Typography>
      </Box>
    </Grid>
  )
}

export default SignIn
