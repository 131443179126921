import { Button, Grid, TextField, CircularProgress } from '@mui/material'
import React, { useState, useContext } from 'react'
import { makeStyles } from '@mui/styles'
import { getFeedbackById, updateFeedbackById } from 'libs/route'
import { AuthContext } from 'contexts/authContext'
import { UserInfo, UserRole } from 'type/userInfo'
import { FeedbackInfo } from 'type/feedbackinfo'

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '4vh' },
  title: {
    textAlign: 'center',
  },
  container: {
    marginTop: 20,
    alignItems: 'top',
    width: '100%',
  },
  border: {
    border: '1px solid black', // add a border to the bottom of each row
  },
  headerCell: {
    backgroundColor: '#f1f1f1',
    color: 'black',
    borderCollapse: 'collapse',
    maxWidth: '300px',
    minWidth: '240px',
    paddingTop: '12px',
    paddingBottom: '12px',
    marginBottom: '8px', // Add margin-bottom for spacing between rows
  },
  narrowHeadCell: {
    backgroundColor: '#f1f1f1',
    color: 'black',
    borderCollapse: 'collapse',
    maxWidth: '120px',
    minWidth: '100px',
    paddingTop: '12px',
    paddingBottom: '12px',
    marginBottom: '16px', // Add margin-bottom for spacing between rows
  },
  narrowCell: {
    maxWidth: '120px',
    minWidth: '100px',
    paddingTop: '12px',
    paddingBottom: '12px',
    marginBottom: '16px', // Add margin-bottom for spacing between rows
  },
  cell: {
    maxWidth: '300px',
    minWidth: '240px',
    paddingTop: '12px',
    paddingBottom: '12px',
    marginBottom: '16px', // Add margin-bottom for spacing between rows
    textAlign: 'center',
  },
  textCell: {
    width: '95%',
    '& .MuiInputBase-input': {
      fontSize: '12px', // Set the size you want here
    },
  },
  styledDateInput: {
    padding: '8px',
    fontSize: '16px',
    border: 'none',
    cursor: 'pointer',
    borderBottom: '1px solid gray', // Add a bottom border
    outline: 'none', // Remove the default outline
  },
  button: {
    backgroundColor: '#ffd40a',
    marginBottom: 20,
    color: 'black',
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
}))

function CommunicationDetailPage({
  userInfo,
  feedback,
  setFeedback,
}: {
  userInfo: UserInfo
  feedback: FeedbackInfo[]
  setFeedback: any
}) {
  const classes = useStyles()
  const [communications, setCommunications] = useState(
    feedback && feedback.length > 0
      ? feedback
      : [
          {
            date: '',
            person: '',
            content: '',
            result: '',
            followUp: '',
            stakeholder: '',
          },
        ]
  )

  const auth = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)

  const addRow = () => {
    setCommunications((prevCommunications) => [
      ...prevCommunications,
      {
        date: '',
        person: '',
        content: '',
        result: '',
        followUp: '',
        stakeholder: '',
      },
    ])
  }

  const onSaveClicked = async () => {
    setIsLoading(true)
    if (JSON.stringify(feedback) === JSON.stringify(communications)) {
      alert('没有修改')
      setIsLoading(false)
      return
    }
    for (const communication of communications) {
      if (
        communication.date === '' ||
        communication.person === '' ||
        communication.content === '' ||
        communication.result === '' ||
        communication.followUp === '' ||
        communication.stakeholder === ''
      ) {
        alert('请填写完整信息')
        setIsLoading(false)
        return
      }
    }

    const response = await updateFeedbackById(userInfo.username, auth, communications)
    // TODO: Use UseError hook
    if (response.status === 200) {
      alert('修改成功')
      const feedbackInfo = await getFeedbackById(userInfo.username, auth.sessionInfo.idToken!)
      setFeedback(feedbackInfo)
    } else {
      alert('修改失败')
    }
    setIsLoading(false)
  }

  const deleteRow = (index) => {
    setCommunications((prevCommunications) => prevCommunications.filter((_, i) => i !== index))
  }

  const handleInputChange = (index, field, value) => {
    setCommunications((prevCommunications) => {
      const updatedCommunications = [...prevCommunications]
      updatedCommunications[index] = {
        ...updatedCommunications[index],
        [field]: value,
      }
      return updatedCommunications
    })
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <Grid>
      <div style={{ overflowX: 'auto', marginLeft: 40, marginRight: 40 }}>
        <table className={classes.container}>
          <thead>
            <tr>
              <th className={classes.narrowHeadCell}>沟通日期</th>
              <th className={classes.narrowHeadCell}>人员</th>
              <th className={classes.headerCell}>沟通内容</th>
              <th className={classes.headerCell}>沟通结果</th>
              <th className={classes.headerCell}>需跟进事项</th>
              <th className={classes.narrowHeadCell}>责任人</th>
            </tr>
          </thead>
          <tbody className={classes.container}>
            {communications.map((communication, index) => (
              <tr key={index}>
                <td className={classes.cell}>
                  <input
                    type="date"
                    id="datepicker"
                    disabled={
                      // Disable for parent role
                      auth.userInfo?.role === UserRole.Parent
                    }
                    className={classes.styledDateInput}
                    onChange={(e) => handleInputChange(index, 'date', e.target.value)}
                    value={communication.date}
                  />
                </td>
                <td className={classes.narrowCell}>
                  <TextField
                    multiline
                    className={classes.textCell}
                    disabled={
                      // Disable for parent role
                      auth.userInfo?.role === UserRole.Parent
                    }
                    value={communication.person}
                    onChange={(e) => handleInputChange(index, 'person', e.target.value)}
                  />
                </td>
                <td className={classes.cell}>
                  <TextField
                    multiline
                    className={classes.textCell}
                    disabled={
                      // Disable for parent role
                      auth.userInfo?.role === UserRole.Parent
                    }
                    value={communication.content}
                    onChange={(e) => handleInputChange(index, 'content', e.target.value)}
                  />
                </td>
                <td className={classes.cell}>
                  <TextField
                    multiline
                    className={classes.textCell}
                    disabled={
                      // Disable for parent role
                      auth.userInfo?.role === UserRole.Parent
                    }
                    value={communication.result}
                    onChange={(e) => handleInputChange(index, 'result', e.target.value)}
                  />
                </td>
                <td className={classes.cell}>
                  <TextField
                    className={classes.textCell}
                    multiline
                    value={communication.followUp}
                    disabled={
                      // Disable for parent role
                      auth.userInfo?.role === UserRole.Parent
                    }
                    onChange={(e) => handleInputChange(index, 'followUp', e.target.value)}
                  />
                </td>
                <td className={classes.narrowCell}>
                  <TextField
                    className={classes.textCell}
                    multiline
                    value={communication.stakeholder}
                    disabled={
                      // Disable for parent role
                      auth.userInfo?.role === UserRole.Parent
                    }
                    onChange={(e) => handleInputChange(index, 'stakeholder', e.target.value)}
                  />
                </td>
                {
                  // Disable for parent or student role
                  auth.userInfo?.role === UserRole.Parent || auth.userInfo?.role === UserRole.Student ? null : (
                    <td>
                      <Button
                        className={classes.button}
                        onClick={() => deleteRow(index)}
                        variant="contained"
                        style={{ marginLeft: 20, float: 'left', width: 80 }}
                      >
                        删除
                      </Button>
                    </td>
                  )
                }
              </tr>
            ))}
          </tbody>
        </table>
        {auth.userInfo!['role'] === UserRole.Parent ? (
          <div />
        ) : (
          <div>
            <Button
              className={classes.button}
              onClick={addRow}
              variant="contained"
              style={{ marginTop: 40, float: 'left' }}
            >
              新增一行
            </Button>
            <Button
              className={classes.button}
              onClick={onSaveClicked}
              variant="contained"
              style={{ marginTop: 40, float: 'left', marginLeft: 40 }}
            >
              保存修改
            </Button>
          </div>
        )}
      </div>
    </Grid>
  )
}

export default CommunicationDetailPage
