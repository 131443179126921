export interface HomestayInfo {
  name?: string
  email?: string
  phone_number?: string
  address?: string
  license_number?: string
}

export type homestayProfileFieldData = {
  elabel: string
  clabel: string
  dataType: string
  canUpdate: boolean
}

type homestayProfileFieldsDictionary = {
  [key: string]: homestayProfileFieldData
}

export const homestayProfileFields: homestayProfileFieldsDictionary = {
  name: {
    elabel: 'Name',
    clabel: '全名',
    dataType: 'string',
    canUpdate: false,
  },
  email: {
    elabel: 'Email',
    clabel: '电子邮箱',
    dataType: 'string',
    canUpdate: false,
  },
  phone_number: {
    elabel: 'Phone Number',
    clabel: '电话号码',
    dataType: 'string',
    canUpdate: true,
  },
  address: {
    elabel: 'Address',
    clabel: '地址',
    dataType: 'string',
    canUpdate: true,
  },
  license_number: {
    elabel: 'License Number',
    clabel: '驾照号',
    dataType: 'string',
    canUpdate: true,
  },
}
