// S3FileUploaderDownloader.js

import React from 'react'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import { Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  fileEntry: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '8px',
    marginTop: '16px',
  },
  fileEntries: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  buttonGroup: {
    display: 'flex', // Group buttons together
    gap: '8px', // Add spacing between buttons
  },
  button: {
    backgroundColor: '#ffd40a',
    color: 'black',
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
}))

const FileTypesComponent = ({ files, title, handleDownload, handleDelete }) => {
  const classes = useStyles()
  return (
    <div className={classes.fileEntry}>
      <Typography variant="h6">{title}</Typography>
      {files.length === 0 ? (
        <div className={classes.fileEntries}>
          <span style={{ paddingTop: 10 }}>无</span>
        </div>
      ) : (
        files.map((file) => (
          <div key={file.value} className={classes.fileEntries}>
            <span style={{ paddingTop: 10 }}>{file.value}</span>
            <div className={classes.buttonGroup}>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => handleDownload(file.value, file.type)}
              >
                下载
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => handleDelete(file.value, file.type)}
              >
                删除
              </Button>
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default FileTypesComponent
